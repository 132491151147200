<section class="menu-wrapper">
  <div class="logo_base"><img src="../assets/Profetlogo.png" alt="Logo" width="140px" class="mt-3" autofocus /></div>
  <mat-nav-list>
    <a
      mat-list-item
      routerLink="/orders/new"
      [ngClass]="{ menu_active: isCurrentRoute('/orders/new') }"
      (click)="slidingSheet.close()"
    >
      <mat-icon>list_alt_add</mat-icon> Create Order
    </a>
    <a
      mat-list-item
      routerLink="/orders"
      [ngClass]="{ menu_active: isCurrentRoute('/orders/orderId') }"
      (click)="slidingSheet.close()"
    >
      <mat-icon>list_alt</mat-icon> Orders
    </a>
    <a
      mat-list-item
      routerLink="/company"
      [ngClass]="{ menu_active: isCurrentRoute('/company') }"
      (click)="slidingSheet.close()"
      *ngIf="
        userState.permittedActions.includes('company_edit_settings') ||
        userState.permittedActions.includes('company_add')
      "
    >
      <mat-icon>domain</mat-icon> Company Settings
    </a>
    <a
      mat-list-item
      routerLink="/user"
      [ngClass]="{ menu_active: isCurrentRoute('/user') }"
      (click)="slidingSheet.close()"
    >
      <mat-icon>person</mat-icon> My Settings
    </a>
  </mat-nav-list>
</section>

<div class="version_wrap"><label>Version: </label>&nbsp;0.14.0</div>
