import { Injectable } from '@angular/core';
import { EndPoints } from '../api-service/api-endpoints';
import { ApiGatewayService } from '../api-service/api-gateway.service';
import { ExceptionConfig } from '../model/error.model';
import { Utilities } from './utilities';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // settings: any = {};
  // enumeration: any = {};
  orderDetails: any = {};

  constructor(private apiGatewayService: ApiGatewayService) {}

  /**
   * Get query params from the URL.
   * Use this method to read query params on page load.
   * Angular's ActivatedRoute.queryParams is not reliable on page load as it initially returns an empty object.
   * Use ActivatedRoute for all other cases.
   */
  public getQueryParams(): Record<string, string> {
    const queryParams: Record<string, string> = {};

    const url = window.location.href;
    const urlParams = new URLSearchParams(new URL(url).search);

    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return queryParams;
  }

  setOrderDetails(orderDetails: any) {
    this.orderDetails = orderDetails;
  }

  getOrderDetails() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.orderDetails;
  }

  getAllFormMetadata(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.getAllFormMetadata + '?' + Utilities.encodeQueryParam(reqBody);
    return this.apiGatewayService.doGet(url, exptnConfig);
  }

  onFormControlValueChange(apiUrl: string, reqBody: any, exptnConfig: ExceptionConfig) {
    const url = (EndPoints.API_URLS as any)[apiUrl] + Utilities.encodeUrlParam(reqBody);
    return this.apiGatewayService.doGet(url, exptnConfig);
  }

  /** Utility fn to compare two javascript objects and return the json-path of modified values */
  // TODO: Return parent object path when old object value is null.
  getModifiedJsonPaths(oldObj: any, newObj: any, path: string = ''): string[] {
    const modifiedKeys: string[] = [];

    for (const key in newObj) {
      const newPath = path ? `${path}.${key}` : key;

      if (Array.isArray(newObj[key])) {
        if (!Array.isArray(oldObj[key])) {
          modifiedKeys.push(newPath);
        } else if (newObj[key].length !== oldObj[key].length) {
          modifiedKeys.push(newPath);
        } else {
          for (let i = 0; i < newObj[key].length; i++) {
            const arrayPath = `${newPath}[${i}]`;

            if (typeof newObj[key][i] === 'object' && typeof oldObj[key][i] === 'object') {
              if (!this._isDeepEqual(newObj[key][i], oldObj[key][i])) {
                modifiedKeys.push(...this.getModifiedJsonPaths(oldObj[key][i], newObj[key][i], arrayPath));
              }
            } else if (newObj[key][i] !== oldObj[key][i]) {
              modifiedKeys.push(arrayPath);
            }
          }
        }
      } else if (
        typeof newObj[key] === 'object' &&
        !Array.isArray(newObj[key]) &&
        !(newObj?.[key] instanceof Date) &&
        newObj?.[key] != null
      ) {
        modifiedKeys.push(...this.getModifiedJsonPaths(oldObj?.[key], newObj?.[key], newPath));
      } else if (oldObj?.[key] !== newObj?.[key]) {
        modifiedKeys.push(newPath);
      }
    }

    return modifiedKeys;
  }

  /**
   * This function is used for formatting property data
   * @param data
   * @returns formatted Data
   */

  formatPropertyData(data: any): any {
    if (Array.isArray(data)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data.map((item) => this.formatPropertyData(item));
    } else if (data != null && typeof data === 'object') {
      const newObj: { [key in string]: unknown } = {};

      Object.keys(data).forEach((dataKey) => {
        let newKey = dataKey;
        if (['lr', 'pr', 'ir', 'ur', 'rr', 'ss', 'as', 'av', 'ue', 'ac', 'sv'].includes(dataKey)) {
          const propertyAbbreviation: { [key in string]: string } = {
            lr: 'listingRecord',
            pr: 'publicRecord',
            ir: 'inspectionRecord',
            ur: 'userRecord',
            rr: 'rentalRecord',
            ss: 'selectedSource',
            as: 'asyncDataStatus',
            av: 'adjustmentValue',
            ue: 'userEntered',
            ac: 'autoComputed',
            sv: 'selectedValue',
          };
          newKey = propertyAbbreviation[dataKey];
        }

        newObj[newKey] = this.formatPropertyData(data[dataKey]);
      });
      return newObj;
    }

    return data;
  }

  private _isDeepEqual(objA: any, objB: any) {
    if (!objA && !objB) return true;

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (typeof objA[key] === 'object' && typeof objB[key] === 'object') {
        if (!this._isDeepEqual(objA[key], objB[key])) {
          return false;
        }
      } else if (objA[key] !== objB[key]) {
        return false;
      }
    }

    return true;
  }
}
