import { Injectable } from '@angular/core';
import { OrderDetail, ValuationType } from 'src/app/module/order/interface/order.interface';
import { ApproachFactoryState } from 'src/app/module/order/state/approach-factory.state';
import {
  PropertyAttribute,
  PropertyAttributeValue,
  PropertyDetail,
} from 'src/app/module/property/interface/property.interface';
import { PropertyService } from 'src/app/module/property/service/property.service';
import { PropertyState } from 'src/app/module/property/state/property.state';
import { PropertyConfig } from 'src/app/shared/config/property.config';
import { OrderMultiDataSourcePipe } from 'src/app/shared/pipes/order-multi-data-source.pipe';
import { SelectedSourceDataPipe } from 'src/app/shared/pipes/selected-source-data.pipe';
import { OrderState } from '../state/order.state';

@Injectable({
  providedIn: 'root',
})
export class ApproachFactoryService {
  constructor(
    private orderState: OrderState,
    private orderMultiDataSourcePipe: OrderMultiDataSourcePipe,
    private approachFactoryState: ApproachFactoryState,
    private propertyState: PropertyState,
    private propertyService: PropertyService,
    private selectedSourceDataPipe: SelectedSourceDataPipe
  ) {}

  public recalculateAndUpdateProperties() {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        this._recalculateAndUpdateSalesProperties();
        break;

      case ValuationType.cost:
        break;

      case ValuationType.shortTermRental:
        this._recalculateAndUpdateStrProperties();
        break;

      default:
        break;
    }
  }

  private _recalculateAndUpdateSalesProperties(): void {
    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    const subjectProperty = structuredClone(this.propertyState.subjectPropertyData) as PropertyDetail;
    const comparableMap = this.approachFactoryState.selectedComparableMap;
    let derivedPriceSum = 0;

    // Compute comparables Adjustment
    for (const propertyId in comparableMap) {
      const comparable = comparableMap[propertyId] as PropertyDetail;

      let totalAdjustmentValue: number = 0;
      for (const [key, characteristic] of Object.entries(comparable)) {
        const ignoreAutoAdjustment = PropertyConfig.propertyFieldConfig[key as PropertyAttribute]?.ignoreAutoAdjustment;
        totalAdjustmentValue +=
          Number(this.orderMultiDataSourcePipe.transform(characteristic?.adjustmentValue, ignoreAutoAdjustment)) || 0;
      }

      comparableMap[propertyId].adjustmentValue = {
        userRecord: Number(totalAdjustmentValue) ?? 0,
        selectedSource: 'userRecord',
      } as PropertyAttributeValue;

      const { price } = this.propertyService.getCurrentStatusAndPrice(comparableMap[propertyId]);

      // Compute Comparable Price
      const totalAdjustedPrice = Number(price ?? 0) + totalAdjustmentValue;
      comparableMap[propertyId].adjustedPrice = {
        userRecord: totalAdjustedPrice,
        selectedSource: 'userRecord',
      } as PropertyAttributeValue;

      const livingArea = Number(
        (this.selectedSourceDataPipe.transform(comparableMap[propertyId].LivingArea) as string) ?? 0
      );
      const perSqFtPrice = Math.round(livingArea ? price / livingArea : 0);
      const adjustedPerSqFtPrice = Math.round(livingArea ? totalAdjustedPrice / livingArea : 0);

      comparableMap[propertyId].pricePerSquareFeet = {
        userRecord: perSqFtPrice,
        selectedSource: 'userRecord',
      } as PropertyAttributeValue;

      comparableMap[propertyId].adjustedPricePerSquareFeet = {
        userRecord: adjustedPerSqFtPrice,
        selectedSource: 'userRecord',
      } as PropertyAttributeValue;

      derivedPriceSum +=
        totalAdjustedPrice *
        (this.selectedSourceDataPipe.transform(comparableMap[propertyId].weightage) ||
          100 / Object.keys(comparableMap).length);
    }

    // Update comparable adjustments and price
    this.approachFactoryState.selectedComparableMap = comparableMap;

    // Compute Subject Property price
    const livingArea = Number((this.selectedSourceDataPipe.transform(subjectProperty.LivingArea) as string) ?? 0);
    const derivedPricePerSqft = livingArea ? Math.round(derivedPriceSum / 100 / livingArea) : 0;

    if (this.approachFactoryState.activeApproachKey && order[this.approachFactoryState.activeApproachKey]) {
      order[this.approachFactoryState.activeApproachKey]!.derivedPrice = Math.round(derivedPriceSum / 100);
      order[this.approachFactoryState.activeApproachKey]!.derivedPricePerSqft = derivedPricePerSqft;
    }

    this.orderState.orderDetail = structuredClone(order);
  }

  private _recalculateAndUpdateStrProperties(): void {
    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    const comparableMap = this.approachFactoryState.selectedComparableMap;
    let derivedMonthlyRentSum = 0;

    // Compute comparables Adjustment
    for (const propertyId in comparableMap) {
      const comparable = comparableMap[propertyId] as PropertyDetail;

      let totalMonthlyAdjustment: number = 0;
      for (const [key, characteristic] of Object.entries(comparable)) {
        const ignoreAutoAdjustment = PropertyConfig.propertyFieldConfig[key as PropertyAttribute]?.ignoreAutoAdjustment;

        totalMonthlyAdjustment +=
          Number(this.orderMultiDataSourcePipe.transform(characteristic?.adjustmentValue, ignoreAutoAdjustment)) || 0;
      }

      comparableMap[propertyId].adjustmentValue = {
        userRecord: Number(totalMonthlyAdjustment) ?? 0,
        selectedSource: 'userRecord',
      } as PropertyAttributeValue;

      const dailyRent = Number(this.selectedSourceDataPipe.transform(comparableMap[propertyId].ListPriceNightly));

      const monthlyRent = (dailyRent * 365) / 12;

      let totalMonthlyAdjustedRent = 0;

      totalMonthlyAdjustedRent = Number(monthlyRent ?? 0) + totalMonthlyAdjustment;

      comparableMap[propertyId].adjustedPrice = {
        userRecord: totalMonthlyAdjustedRent,
        selectedSource: 'userRecord',
      } as PropertyAttributeValue;

      derivedMonthlyRentSum +=
        totalMonthlyAdjustedRent *
        (this.selectedSourceDataPipe.transform(comparableMap[propertyId].weightage) ||
          100 / Object.keys(comparableMap).length);
    }

    // Update comparable adjustments and price
    this.approachFactoryState.selectedComparableMap = comparableMap;

    // Compute Subject Property price
    order.shortTermRental!.derivedPrice = Math.round(derivedMonthlyRentSum / 100);

    this.orderState.orderDetail = structuredClone(order);
  }
}
