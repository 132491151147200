import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function allowSpecialCharsValidator(specialChars: string[] | undefined): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // If the input is empty, return null (valid)
    if (!control.value) return null;

    // If `specialChars` is undefined, allow all characters (no validation)
    if (specialChars === undefined) return null;

    let allowedRegex = /^[a-zA-Z0-9]*$/; // Only letters and numbers allowed

    if (specialChars) {
      // Build regex to allow letters, numbers, and only the specified special characters
      const escapedChars = specialChars.map((char) => `\\${char}`).join('');
      allowedRegex = new RegExp(`^[a-zA-Z0-9${escapedChars}]*$`);
    }

    if (!allowedRegex.test(control.value)) {
      return { invalidCharacters: true };
    }

    return null; // Valid case
  };
}
