export interface ReportSetting {
  /** @deprecated Use `.key` instead */
  enumValue: string;

  /** @deprecated Use `.displayValue` instead */
  enumDisplayValue: string;
  key: string;
  displayValue: string;
  reportTypes?: ReportSubType[] | null;
  image?: string;
}

export interface ReportSubType {
  /** @deprecated Use `.key` instead */
  enumValue: string;

  /** @deprecated Use `.displayValue` instead */
  enumDisplayValue: string;
  key: string;
  displayValue: string;
  reportForms?: string[] | null;
  reportFormList?: ReportForm[] | null;
}

interface ReportForm {
  key?: string | null;
  displayValue?: string;
}

export enum OrderType {
  complexity = 'complexityReport',
  property = 'propertyReport',
  valuation = 'valuationReport',
  shortTermRental = 'shortTermRentalReport',
  bpo = 'bpoReport',
}

export enum ValuationOrderSubType {
  appraisal = 'appraisalReport',
  desktop = 'desktopAppraisalReport',
  evaluation = 'evaluationReport',
  avm = 'avmReport',
  bpo = 'bpoReport',
  rental = 'shortTermRentalReport',
}

export type OrderSubType = ValuationOrderSubType;

export enum AllOrderType {
  complexity = 'complexityReport',
  property = 'propertyReport',
  appraisal = 'appraisalReport',
  desktop = 'desktopAppraisalReport',
  evaluation = 'evaluationReport',
  rental = 'shortTermRentalReport',
}

export type CombinedOrderType = OrderSubType | 'complexityReport' | 'propertyReport';
