<app-progress-loader />

<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav #slidingSheet [fixedInViewport]="true">
    <ng-template [sliding-sheet]="slidingSheet"></ng-template>
  </mat-sidenav>
  <mat-sidenav-content class="app-sidenav-content">
    <lib-header *ngIf="authState.isAuthenticated$ | async">
      <div class="d-flex w-100 justify-content-end pe-3 border-end">
        <div>
          <a mat-stroked-button color="primary" (click)="createOrder()">
            <mat-icon>list_alt_add</mat-icon>
            Create Order
          </a>
        </div>
      </div>
    </lib-header>

    <!-- All pages rendered here -->
    <main class="body_wrap">
      <router-outlet />
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
