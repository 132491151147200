import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { NewOrderApi } from 'src/app/module/new-order/api/new-order.api';
import {
  PropertySearchApiResponse,
  PropertySearchByMLSApiResponse,
  SearchMode,
  SearchedProperty,
  SearchedPropertyData,
} from 'src/app/module/new-order/interface/new-order.interface';
import { OrderApi } from 'src/app/module/order/api/order.api';
import { MLSBoard } from 'src/app/module/order/interface/order.interface';
import { PropertyApi } from 'src/app/module/property/api/property.api';
import {
  GenerateListingSheetApiRequest,
  GenerateListingSheetApiResponse,
  PropertySearchCriteria,
} from 'src/app/module/property/interface/property.interface';
import { ShortTermRentalApi } from 'src/app/module/short-term-rental/api/short-term-rental.api';
import {
  RadiusSearchCriteria,
  SearchedComparable,
} from 'src/app/module/short-term-rental/interface/short-term-rental.interface';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class ProxyService {
  constructor(
    private newOrderApi: NewOrderApi,
    private snackBarService: SnackBarService,
    private orderApi: OrderApi,
    private propertyApi: PropertyApi,
    private shortTermRentalApi: ShortTermRentalApi
  ) {}

  searchProperty(searchCriteria: PropertySearchCriteria, mode?: SearchMode): Observable<SearchedPropertyData> {
    if (mode == 'mls') {
      return this.newOrderApi.searchPropertyByMLS(searchCriteria).pipe(
        map((data: PropertySearchByMLSApiResponse) => {
          if (!data?.ListingDetails?.length) {
            this.snackBarService.open('error', (data?.Status?.Message as string) || 'Property search failed.');
          }
          return this._formatSearchResult(data);
        }),
        catchError(({ error }: HttpErrorResponse) => {
          this.snackBarService.open('error', (error?.Status?.Message as string) || 'Property search failed.');
          return of({ propertyList: [], count: 0 });
        })
      );
    } else {
      return this.newOrderApi.searchProperty(searchCriteria).pipe(
        map((res: PropertySearchApiResponse) => {
          if (res?.Data?.Properties?.length) return this._formatSearchResult(res.Data);
          else {
            this.snackBarService.open('error', (res?.Status?.Message as string) || 'Property search failed.');
            return this._formatSearchResult({ Properties: [], Count: 0 });
          }
        }),
        catchError(({ error }: HttpErrorResponse) => {
          this.snackBarService.open('error', (error?.Status?.Message as string) || 'Property search failed.');
          return of({ propertyList: [], count: 0 });
        })
      );
    }
  }

  getMLSBoards(params: any): Observable<any> {
    return this.orderApi.getMLSBoards(params).pipe(
      map((res: MLSBoard[]) => {
        const list = res?.reduce((acc: MLSBoard[], cur: MLSBoard) => {
          const i: number = acc.findIndex((l: MLSBoard) => l.MLSBoard == cur.MLSBoard);
          if (i > -1) {
            acc[i].MLSName = acc[i].MLSName + ',' + cur.MLSName;
          } else {
            acc.push(cur);
          }
          return acc;
        }, []);
        return list ?? [];
      }),
      catchError(({ error }: HttpErrorResponse) => {
        this.snackBarService.open('error', (error?.ErrorDescription as string) || 'Failed to fetch MLS Boards');
        return of([]);
      })
    );
  }

  initPropertyPdfGeneration(requestBody: GenerateListingSheetApiRequest): Observable<number | undefined> {
    return this.propertyApi.initPropertyPdfGeneration(requestBody).pipe(
      map((res: GenerateListingSheetApiResponse) => {
        return res?.Data?.ReferenceId;
      }),
      catchError(({ error }: HttpErrorResponse) => {
        this.snackBarService.open('error', (error?.Status?.Message as string) || 'Unable to download Property PDF.');
        return of(undefined);
      })
    );
  }

  fetchGeneratedPropertyPdf(referenceId: number): Observable<string | undefined> {
    return this.propertyApi.fetchGeneratedPropertyPdf(referenceId).pipe(
      map((res: any) => {
        return (res?.data?.responseData as string) || undefined;
      }),
      catchError(({ error }: HttpErrorResponse) => {
        this.snackBarService.open('error', (error?.Status?.Message as string) || 'Unable to download Property PDF.');
        return of(undefined);
      })
    );
  }

  getCompsByRadiusSearch(searchCriteria: RadiusSearchCriteria) {
    return this.shortTermRentalApi.getCompsByRadiusSearch(searchCriteria).pipe(
      map((res) => {
        if (!res?.Data?.Listings?.length) {
          this.snackBarService.open('error', (res?.Status?.Message as string) || 'Comparable search failed.');
        }
        return this._formatSearchByRadiusResult(res?.Data);
      }),
      catchError(() => {
        return of({ propertyList: [], count: 0 });
      })
    );
  }

  private _formatSearchResult(data?: any): SearchedPropertyData {
    let propertyDetails: any[] = [];
    let count = 0;

    if (data?.Properties?.length) {
      propertyDetails = data.Properties;
      count = data.Count;
    } else if (data?.ListingDetails?.length) {
      propertyDetails = data.ListingDetails;
      count = data.ListingDetails.length;
    }

    const list: SearchedProperty[] =
      propertyDetails?.reduce((acc: SearchedProperty[], cur: any) => {
        const property: SearchedProperty = {
          Address: cur.Address,
          StreetAddress: cur.StreetAddress ?? cur.StdAddress?.UnparsedAddress,
          City: cur.City ?? cur.StdAddress?.City,
          StateOrProvince: cur.StateOrProvince ?? cur.StdAddress?.StateOrProvince,
          PostalCode: cur.PostalCode ?? cur.StdAddress?.PostalCode,
          ImageURLs: Array.isArray(data?.ImageURLs) ? data.ImageURLs[0] : (cur.ImageURLs ?? ''),
          ParcelNumber: cur.ParcelNumber,
          Source: cur.Source,
          MlsListingNumber: cur.MlsListingNumber,
          PropertyType: cur.PropertyType,
          BedroomsTotal: cur.BedroomsTotal,
          BathroomsTotalInteger: cur.BathroomsTotalInteger,
          BathroomsDecimal: cur.BathroomsDecimal,
          LivingArea: cur.LivingArea,
          LotSizeSquareFeet: cur.LotSizeSquareFeet,
          CloseDate: cur.CloseDate ?? cur.LastSaleDate,
          ClosePrice: cur.ClosePrice ?? cur.LastSalePrice,
          PropertySubType: cur.PropertySubType,
          Latitude: cur.Latitude,
          Longitude: cur.Longitude,
          ListingId: cur.ListingId,
          FIPS: cur.FIPS,
          MlsBoard: cur.MlsBoard,
          PMXPropertyId: cur.PMXPropertyId,
          OwnerName: cur.OwnerName,
          DataSource: cur.DataSource,
          UnitNumber: cur.UnitNumber,
          PublicRecord: cur?.PublicRecord ?? {},
        };

        acc.push(property);
        return acc;
      }, []) ?? [];

    return { propertyList: list, count: count };
  }

  private _formatSearchByRadiusResult(data?: any) {
    const propertyDetails: any[] = data?.Listings?.length ? data.Listings : [];
    const count = data?.TotalComparables ?? 0;

    const list: SearchedComparable[] =
      propertyDetails?.reduce((acc: SearchedComparable[], cur: any) => {
        const property: SearchedComparable = {
          ImageURLs: cur.ImageURLs ?? [],

          StreetAddress: cur.StreetAddress,
          City: cur.City,
          StateOrProvince: cur.StateOrProvince,
          PostalCode: cur.PostalCode,

          Distance: cur.Distance,

          BedroomsTotal: cur.BedroomsTotal,
          BathroomsDecimal: cur.BathroomsDecimal,
          BathroomsFullCalculated: cur.BathroomsFull,
          BathroomsHalfCalculated: cur.BathroomsPartial,

          LivingArea: cur.LivingArea,
          LotSizeSquareFeet: cur.LotSizeSquareFeet,

          PropertySubType: cur.PropertySubType,

          CloseDate: cur.CloseDate ?? cur.LastSaleDate,
          ClosePrice: cur.ClosePrice ?? cur.LastSalePrice,

          OwnerName: cur.Owner1FullName,
          Flooring: cur.Flooring,
          RoofCoverType: cur.RoofCoverType,

          RoomsTotal: cur.RoomsTotal,
          Heating: cur.Heating,
          Cooling: cur.Cooling,

          ParkingSpaces: cur.ParkingSpaces,
          FireplaceYN: cur.FireplaceYN,
          ArchitecturalStyle: cur.ArchitecturalStyle,

          ConstructionQuality: cur.ConstructionQuality,
          Stories: cur.Stories,
          YearBuilt: cur.YearBuilt,

          Latitude: cur.Latitude,
          Longitude: cur.Longitude,
          PMXPropertyId: cur.PMXPropertyId,
        };

        acc.push(property);
        return acc;
      }, []) ?? [];

    return { propertyList: list, count: count };
  }
}
