import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Rule, ValidationResult, ValidationRule } from '../interface/validation.interface';

@Injectable({
  providedIn: 'root',
})
export class ValidationState {
  /** State where validation List is stored */
  private _validationResultSub$ = new BehaviorSubject<ValidationResult[] | null>(null);
  validationResult$: Observable<ValidationResult[] | null> = this._validationResultSub$.asObservable();

  get validationResult(): ValidationResult[] | null {
    return this._validationResultSub$.value;
  }

  set validationResult(data: ValidationResult[] | null) {
    this._validationResultSub$.next(data);
  }

  /** state to store the  info when selecting rule from the validation list */
  private _selectedRuleSub$ = new BehaviorSubject<ValidationResult | null>(null);
  selectedRule$: Observable<ValidationResult | null> = this._selectedRuleSub$.asObservable();

  get selectedRule(): ValidationResult | null {
    return this._selectedRuleSub$.value;
  }

  set selectedRule(data: ValidationResult | null) {
    this._selectedRuleSub$.next(data);
  }

  /** state to store all front end validation rules from the api */
  private _validationRulesSub$ = new BehaviorSubject<Array<Rule> | null>(null);
  validationRules$: Observable<Array<Rule> | null> = this._validationRulesSub$.asObservable();

  get validationRules(): Array<Rule> | null {
    return this._validationRulesSub$.value;
  }

  set validationRules(data: Array<Rule> | null) {
    this._validationRulesSub$.next(data);
  }

  /** state to store all modified front end validation rules from the api */
  private _validationModifiedRulesSub$ = new BehaviorSubject<Array<ValidationRule> | null>(null);
  validationModifiedRules$: Observable<Array<ValidationRule> | null> = this._validationModifiedRulesSub$.asObservable();

  get validationModifiedRules(): Array<ValidationRule> | null {
    return this._validationModifiedRulesSub$.value;
  }

  set validationModifiedRules(data: Array<ValidationRule> | null) {
    this._validationModifiedRulesSub$.next(data);
  }

  /** state to store last audit time */
  private _validationAuditTimeSub$ = new BehaviorSubject<Date | null>(null);
  validationAuditTime$: Observable<Date | null> = this._validationAuditTimeSub$.asObservable();

  get validationAuditTime(): Date | null {
    return this._validationAuditTimeSub$.value;
  }

  set validationAuditTime(data: Date | null) {
    this._validationAuditTimeSub$.next(data);
  }

  /** state to store validation-sheet-toggle value */
  private _validationSheetToggleSub$ = new BehaviorSubject<boolean>(false);
  validationSheetToggle$: Observable<boolean> = this._validationSheetToggleSub$.asObservable();

  get validationSheetToggle(): boolean {
    return this._validationSheetToggleSub$.value;
  }

  set validationSheetToggle(data: boolean) {
    this._validationSheetToggleSub$.next(data);
  }
}
