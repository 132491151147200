<div class="table-responsive">
  <table class="gen_table w-100 table table-bordered table-hover">
    <thead [hidden]="tableConfig.hideHeading">
      <tr>
        <th *ngFor="let columnHead of tableConfig.columns">
          {{ columnHead.title }}
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let columnData of tableContent; index as i">
        <tr (click)="$event.stopPropagation(); actionTrigger(columnData, 'details'); changeSelection(i)">
          <td
            *ngFor="let columnHead of tableConfig.columns"
            [ngStyle]="{
              backgroundColor: selectedIndex === i ? 'var(--bs-focus-ring-color)' : 'initial',
              fontWeight: selectedIndex === i ? '500' : 'initial',
            }"
          >
            <div *ngIf="columnHead.dataProperty == 'NO' && tableConfig.useTableIndexSerial; else data_view">
              <ng-container *ngIf="!enablePagination; else paginator_index"> {{ i + 1 }} </ng-container>
              <ng-template #paginator_index>
                {{ pageSize * pageIndex + (i + 1) }}
              </ng-template>
            </div>
            <ng-template #data_view>
              <ng-container *ngIf="columnHead.dataProperty !== 'ROW_ACTIONS'; else action_content">
                <ng-container *ngIf="columnHead.dataProperty.split('.').length == 1; else sub_key">
                  <ng-container *ngIf="_array.isArray(columnData[columnHead.dataProperty]); else simple_values">
                    <ng-container *ngFor="let val of columnData[columnHead.dataProperty]; index as v">
                      <span *ngIf="v > 0"> , </span>
                      {{ columnHead.pipe ? (val | dynamicPipe: columnHead.pipe : columnHead.arg) : val }}
                    </ng-container>
                  </ng-container>
                  <ng-template #simple_values>
                    {{
                      columnHead.pipe
                        ? (columnData[columnHead.dataProperty] | dynamicPipe: columnHead.pipe : columnHead.arg)
                        : columnData[columnHead.dataProperty]
                    }}
                  </ng-template>
                </ng-container>
                <ng-template #sub_key>
                  {{
                    columnHead.pipe
                      ? (getColumnDataByKey(columnData, columnHead.dataProperty)
                        | dynamicPipe: columnHead.pipe : columnHead.arg)
                      : getColumnDataByKey(columnData, columnHead.dataProperty)
                  }}
                </ng-template>
              </ng-container>
            </ng-template>
            <ng-template #action_content>
              <ng-container *ngIf="columnHead.rowActionType == 'menu'; else image_action">
                <div style="width: 70px; text-align: center" (click)="$event.stopPropagation()">
                  <img [matMenuTriggerFor]="menu" src="../../../assets/images/threedot.png" width="25" />
                </div>
                <mat-menu #menu="matMenu">
                  <ng-container *ngFor="let action of columnHead.rowActions">
                    <ng-container *ngIf="action.showOption; else no_option">
                      <button
                        mat-menu-item
                        *ngIf="action.showOption(columnData)"
                        (click)="actionTrigger(columnData, action.actionIdToReturn)"
                      >
                        {{ action.label }}
                      </button>
                    </ng-container>
                    <ng-template #no_option>
                      <button mat-menu-item (click)="actionTrigger(columnData, action.actionIdToReturn)">
                        {{ action.label }}
                      </button>
                    </ng-template>
                  </ng-container>
                </mat-menu>
              </ng-container>
              <ng-template #image_action>
                <div class="mx-auto text-center" (click)="$event.stopPropagation()">
                  <ng-container *ngIf="columnHead.rowActionType == 'collapse'; else checkbox">
                    <ng-container *ngFor="let action of columnHead.rowActions">
                      <img
                        [src]="action.imageUrl"
                        width="16px"
                        (click)="$event.stopPropagation(); openRow(columnData)"
                      />
                    </ng-container>
                  </ng-container>
                  <ng-template #checkbox>
                    <ng-container *ngIf="columnHead.rowActionType == 'checkbox'; else radio">
                      <ng-container *ngFor="let action of columnHead.rowActions">
                        <input type="checkbox" (click)="actionTrigger(columnData, action.actionIdToReturn)" />
                      </ng-container>
                    </ng-container>
                  </ng-template>
                  <ng-template #radio>
                    <ng-container *ngIf="columnHead.rowActionType == 'radio'; else image1_action">
                      <ng-container *ngFor="let action of columnHead.rowActions">
                        <input
                          type="radio"
                          name="radioGroup"
                          [checked]="columnData.selected"
                          (click)="
                            $event.stopPropagation();
                            actionTrigger(columnData, action.actionIdToReturn);
                            changeSelection(i)
                          "
                        />
                      </ng-container>
                    </ng-container>
                  </ng-template>
                  <ng-template #image1_action>
                    <ng-container *ngFor="let action of columnHead.rowActions">
                      <div *ngIf="action.imageUrl; else button_action">
                        <img
                          [src]="action.imageUrl"
                          width="22px"
                          style="margin-left: 15px"
                          (click)="actionTrigger(columnData, action.actionIdToReturn)"
                        />
                      </div>

                      <ng-template #button_action>
                        <button
                          mat-stroked-button
                          color="primary"
                          class="small_btn float-start"
                          (click)="actionTrigger(columnData, action.actionIdToReturn)"
                          [hidden]="columnData.actionHide"
                        >
                          {{ action.label }}
                        </button>
                      </ng-template>
                    </ng-container>
                  </ng-template>
                </div>
              </ng-template>
            </ng-template>
          </td>
        </tr>
        <ng-container *ngIf="columnData.expand && columnData.subList.length">
          <tr *ngFor="let subColumnData of columnData.subList">
            <td *ngFor="let columnHead of tableConfig.columns">
              {{ subColumnData[columnHead.dataProperty] }}
            </td>
          </tr>
        </ng-container>
        <!-- <tr *ngIf="columnData.expand">
          
          <app-data-table-wrapper
            *ngIf="columnData.subList.length"
            [tableContent]="columnData.subList"
            [tableConfig]="columnData.subTableColumnDef"
          />
        </tr> -->
      </ng-container>
    </tbody>
  </table>
</div>
<div *ngIf="enablePagination">
  <app-paginator [paginationData]="paginationData" (paginatorAction)="pagination($event)"></app-paginator>
</div>
