import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-no-content-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './no-content-banner.component.html',
  styleUrls: ['./no-content-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoContentBannerComponent {
  @Input() bannerTitle: string = 'No Content Found';
  @Input() bannerSubTitle?: string;
  @Input() layout: 'vertical' | 'horizontal' = 'vertical';

  @HostBinding('class.horizontal') get isHorizontal() {
    return this.layout === 'horizontal';
  }
}
