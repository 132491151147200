import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HttpInterceptorFn, provideHttpClient, withInterceptors } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonHeaderModule, CommonHeaderService, HeaderConfig, PROFET_APPS } from '@propmix/profet-common-header';
import { ProfetEulaConfig } from '@propmix/profet-eula';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { Observable } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './module/angular-material/angular-material.module';
import { BathroomDecimalPipe } from './module/property/pipe/bathroom-decimal.pipe';
import { SyncModule } from './module/sync/sync.module';
import { SlidingSheetDirective } from './shared/directives/sliding-sheet.directive';
import { InitializerService } from './shared/initializer/initializer.service';
import { authTokenInterceptor } from './shared/interceptor/auth-token.interceptor';
import { DynamicPipe } from './shared/pipes/dynamic.pipe';
import { EmptyDataPipe } from './shared/pipes/empty-data.pipe';
import { EnumRefPipe } from './shared/pipes/enum-ref.pipe';
import { OrderMultiDataSourcePipe } from './shared/pipes/order-multi-data-source.pipe';
import { SelectedSourceDataPipe } from './shared/pipes/selected-source-data.pipe';
import { SimpleDatePipe } from './shared/pipes/simple-date.pipe';
import { UnitAffixPipe } from './shared/pipes/unitAffix.pipe';
import { SharedModule } from './shared/shared.module';

export function appInitializer(initializerService: InitializerService): () => Observable<void> {
  return () => initializerService.initializeApp();
}

export function httpInterceptorFactory(): HttpInterceptorFn[] {
  const interceptorList: HttpInterceptorFn[] = [authTokenInterceptor];

  // if (ENV.useMockApi) {
  //   interceptorList.push(mockApiInterceptor);
  // }

  return interceptorList;
}

export function initializeHeaderConfig(): HeaderConfig {
  const headerConfig: HeaderConfig = {
    apiUrl: `${ENV.portal.backendBaseUrl}`,
    appType: PROFET_APPS.appraisal_valuation,
    portalFrontendUrl: ENV.portal.frontendBaseUrl,
    signOutUrl: `${ENV.logInUrl}`,
  };

  return headerConfig;
}

const profetEulaConfig: ProfetEulaConfig = {
  portalBackendBaseUrl: ENV.portal.backendBaseUrl,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AngularMaterialModule,
    SlidingSheetDirective,
    SyncModule,
    CommonHeaderModule,
  ],
  providers: [
    provideHttpClient(withInterceptors(httpInterceptorFactory())),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [InitializerService],
      multi: true,
    },
    SelectedSourceDataPipe,
    CurrencyPipe,
    DecimalPipe,
    SimpleDatePipe,
    DatePipe,
    EnumRefPipe,
    DynamicPipe,
    EmptyDataPipe,
    BathroomDecimalPipe,
    OrderMultiDataSourcePipe,
    provideEnvironmentNgxMask(),
    UnitAffixPipe,
    CommonHeaderService,
    { provide: 'headerConfig', useFactory: initializeHeaderConfig },
    { provide: 'PROFET_EULA_CONFIG', useValue: profetEulaConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
