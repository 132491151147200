import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import { ValuationType } from '../../order/interface/order.interface';
import {
  ConfirmPropertyApiRes,
  ConfirmPropertyParam,
  ConfirmPropertyRes,
  RadiusSearchCriteria,
} from '../interface/short-term-rental.interface';

@Injectable({
  providedIn: 'root',
})
export class ShortTermRentalApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  /** Confirm comparable */
  confirmProperty(params: ConfirmPropertyParam): Observable<ConfirmPropertyRes> {
    const httpParams: any = {
      ...params,
      valuationType: ValuationType.shortTermRental,
    };

    return this._http
      .post<CommonApiResponse<ConfirmPropertyApiRes>>(`${ENV.backendBaseUrl}/api/confirmRentalCompProperty`, httpParams)
      .pipe(
        map((res) => res?.data?.confirmPropertyStatus),
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.open('error', (error?.error?.message as string) || 'Failed to confirm property');
          return throwError(() => error);
        })
      );
  }

  getCompsByRadiusSearch(params: RadiusSearchCriteria): Observable<any> {
    const httpParams: any = {
      api: '/pubrec/assessor/v2/GetPropertiesByRadius',
      authType: 'static',
      apiType: 'data',
      ...params,
    };
    return this._http
      .get<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/proxy/get`, {
        params: httpParams,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.open('error', (error?.error?.Status?.Message as string) || 'Failed to confirm property');
          return throwError(() => error);
        })
      );
  }
}
