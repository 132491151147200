import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { ExtendedPropertyApiDetail } from 'src/app/module/property/interface/property.interface';
import { CommonApiResponse, UserPermittedActionsResponse } from 'src/app/shared/interface/common.interface';
import { OrderType } from 'src/app/shared/interface/report.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import {
  UnitAdjustmentRecommendationApiResponse,
  UnitAdjustmentRecommendationSet,
} from '../../adjustment-grid/interface/adjustment.interface';
import { SyncUpdateSet } from '../../sync/interface/sync.interface';
import { CertificationResponse } from '../interface/certification.interface';
import { DwellingCostApiResponse, DwellingRequest } from '../interface/cost-approach.interface';
import { NeighborhoodAnalysisApiRequest, NeighborhoodInsight } from '../interface/neighborhood.interface';
import {
  AddCustomCompRes,
  AllStateCountyFips,
  GeocodeApiResponse,
  GetComparablePropertyApiResponse,
  GetSelectedComparablePropertyApiResponse,
  OrderDetail,
  OrderDetailApiResponse,
  PrimarySearchFilter,
  ValidateComparableExistsAPIRes,
  ValidateComparableExistsRequest,
  ValuationType,
} from '../interface/order.interface';
import { PreviewDocumentApiRequest } from '../interface/preview.interface';
import { SimilarityScoreAttributes } from '../interface/similarity-score.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  /** Get details of an order by order id */
  getOrderDetail(orderId: string, action?: string | null): Observable<OrderDetail> {
    const httpParams: any = { id: orderId, ...(action ? { action: action } : {}) };

    return this._http
      .get<CommonApiResponse<OrderDetailApiResponse>>(`${ENV.backendBaseUrl}/api/orders/getOrderById`, {
        params: httpParams,
      })
      .pipe(
        map((res) => res.data.order),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /** Initiate comparable generation based on the criteria provided */
  searchComparables(orderId: string, params: PrimarySearchFilter | null | undefined): Observable<unknown> {
    const httpParams: any = {
      id: orderId,
      ...params,
    };
    return this._http.post<CommonApiResponse<unknown>>(`${ENV.backendBaseUrl}/api/searchComparables`, httpParams).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }

  /** Get comparables based on order id and applied filters */
  getComparables(params: any): Observable<GetComparablePropertyApiResponse> {
    return this._http
      .post<CommonApiResponse<GetComparablePropertyApiResponse>>(`${ENV.backendBaseUrl}/api/getComparables`, params)
      .pipe(map((res) => res.data));
  }

  /** Get only selected comparables based on order id */
  getSelectedComparables(orderId: string, valuationType: ValuationType): Observable<ExtendedPropertyApiDetail[]> {
    const httpParams: any = { id: orderId, valuationType };
    return this._http
      .get<CommonApiResponse<GetSelectedComparablePropertyApiResponse>>(
        `${ENV.backendBaseUrl}/api/getSelectedComparables`,
        {
          params: httpParams,
        }
      )
      .pipe(map((res) => res.data.selectedComparables));
  }

  /** Initiate similarity score recomputation */
  computeSimilarityScore(orderId: string, similarityScores: SimilarityScoreAttributes): Observable<unknown> {
    return this._http
      .post<
        CommonApiResponse<unknown>
      >(`${ENV.backendBaseUrl}/api/computeSimilarityScore?id=${orderId}`, similarityScores)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  downloadComparables(params: any): Observable<void> {
    const queryParams = new URLSearchParams();
    queryParams.set('id', params.id);
    const fileType = params.type === 'pdf' ? 'application/pdf' : 'text/csv';
    queryParams.set('type', fileType);
    const apiUrlWithParams = `${ENV.backendBaseUrl}/api/compsExportService?${queryParams.toString()}`;
    return this._http.post(apiUrlWithParams, null, { responseType: 'blob' }).pipe(
      map((res: Blob) => {
        const blob = new Blob([res], { type: fileType });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `comparable-properties.${params.type}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        return;
      }),
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }

  /**Get geocode information */
  getGeocode(params: any): Observable<GeocodeApiResponse> {
    const httpParams: any = {
      api: '/v1/apiGeoCode',
      authType: 'static',
      apiType: 'geocode',
      ...params,
    };
    return this._http
      .get<CommonApiResponse<GeocodeApiResponse>>(`${ENV.backendBaseUrl}/api/proxy/get`, { params: httpParams })
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /** Add comparable */
  addComparable(params: any): Observable<AddCustomCompRes> {
    const httpParams: any = {
      ...params,
    };
    return this._http
      .post<CommonApiResponse<AddCustomCompRes>>(`${ENV.backendBaseUrl}/api/addComparables`, httpParams)
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /** Remove comparable */
  removeComparable(propertyId: string): Observable<any> {
    return this._http.delete<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/removeComparable/${propertyId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }

  /** Add custom comparable */
  addCustomComparable(params: any): Observable<AddCustomCompRes> {
    const httpParams: any = {
      ...params,
    };
    return this._http
      .post<CommonApiResponse<AddCustomCompRes>>(`${ENV.backendBaseUrl}/api/addNewCustomComparables`, httpParams)
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          this.snackBarService.open('error', (error?.error?.message as string) || 'Failed to add custom comparable.');
          return throwError(() => error);
        })
      );
  }

  retrieveDwellingCostData(params: DwellingRequest): Observable<DwellingCostApiResponse> {
    return this._http
      .post<CommonApiResponse<DwellingCostApiResponse>>(`${ENV.backendBaseUrl}/api/getDwellingCostData`, params)
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /** Updating order */
  updateOrder(orderId: string, orderType: OrderType, params: SyncUpdateSet[]): Observable<boolean> {
    return this._http
      .patch<
        CommonApiResponse<SyncUpdateSet>
      >(`${ENV.backendBaseUrl}/api/updateOrder/${orderId}?orderType=${orderType}`, params)
      .pipe(
        map(() => true),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return of(false);
        })
      );
  }

  getUnitAdjustmentRecommendation(orderId: string): Observable<UnitAdjustmentRecommendationSet | null> {
    return this._http
      .get<
        CommonApiResponse<UnitAdjustmentRecommendationApiResponse>
      >(`${ENV.backendBaseUrl}/api/getAdjustmentRecommendation`, { params: { id: orderId } })
      .pipe(
        map((res) => res.data.adjustment),
        catchError(() => of(null))
      );
  }

  /** Get Neighborhood Analysis Data */
  getNeighborhoodAnalysis(params: NeighborhoodAnalysisApiRequest): Observable<any> {
    return this._http.post<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/order/getNeighborhoodAnalysis`, params);
  }

  /** Get Neighborhood Insights for charts */
  getNeighborhoodInsight(orderId: string, refreshData: boolean = false): Observable<NeighborhoodInsight> {
    return this._http
      .get<CommonApiResponse<NeighborhoodInsight>>(`${ENV.backendBaseUrl}/api/insights`, {
        params: { id: orderId, refreshData },
      })
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  getInspectionRecord(id: string): Observable<any> {
    const params = new HttpParams().set('id', id);
    return this._http
      .post<CommonApiResponse>(`${ENV.backendBaseUrl}/api/order/getInspectionRecord`, null, { params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  getAccessControl(orderObjectId: string): Observable<string[]> {
    const httpParams: any = {
      scope: 'order',
      orderObjectId,
    };
    return this._http
      .get<CommonApiResponse<UserPermittedActionsResponse>>(`${ENV.backendBaseUrl}/api/accessControl`, {
        params: httpParams,
      })
      .pipe(
        map((res) => res.data.permittedActions),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  updateInspectionDetails(inspectionUpdateRequest: any): Observable<any> {
    return this._http.post<CommonApiResponse>(
      `${ENV.backendBaseUrl}/api/order/updateInspectionDetails`,
      inspectionUpdateRequest,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }
    );
  }

  getLicenseDetails(orderId: string, refreshData: boolean = false): Observable<CertificationResponse> {
    return this._http
      .get<CommonApiResponse<CertificationResponse>>(`${ENV.backendBaseUrl}/api/order/getLicenseDetails`, {
        params: { orderId: orderId, refreshData },
      })
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  signOrder(orderId: string): Observable<any> {
    const params = new HttpParams().set('orderId', orderId);
    return this._http
      .post<CommonApiResponse>(`${ENV.backendBaseUrl}/api/order/signOrder`, null, {
        params,
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /**Get preview file
   * @param id Order Id
   * @param documentType Type of the document to return
   */
  getPreviewDocument(params: PreviewDocumentApiRequest): Observable<any> {
    const httpParams: any = { ...params };
    return this._http.get(`${ENV.backendBaseUrl}/api/exportHTML`, { params: httpParams, responseType: 'blob' }).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }

  getReportDocument(params: PreviewDocumentApiRequest): Observable<HttpResponse<Blob> | false> {
    let downloadEndpoint = '';
    switch (params.documentType) {
      case 'PDF':
        downloadEndpoint = 'api/exportPDF';
        break;
      case 'MISMO':
        downloadEndpoint = 'api/exportMISMO';
        break;
      default:
        downloadEndpoint = 'api/exportPDF';
    }
    const httpParams: any = { orderObjectId: params.orderObjectId };
    return this._http
      .get(`${ENV.backendBaseUrl}/${downloadEndpoint}`, {
        params: httpParams,
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /**Get All State CountyFips*/
  getAllStateCountyFips(): Observable<AllStateCountyFips> {
    return this._http
      .get<CommonApiResponse<AllStateCountyFips>>(`${ENV.backendBaseUrl}/api/getAllStateCountyFips`)
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /**Get MLs Boards */
  getMLSBoards(params: any): Observable<any> {
    const httpParams: any = {
      api: '/mls/v3/GetMLSBoards',
      authType: 'app_static',
      apiType: 'data',
      ...params,
    };

    return this._http.get<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/proxy/get`, { params: httpParams });
  }

  /**
   * Checking comparable exists in comparable list
   */
  validateComparableExists(params: ValidateComparableExistsRequest): Observable<ValidateComparableExistsAPIRes | null> {
    return this._http
      .post<
        CommonApiResponse<ValidateComparableExistsAPIRes>
      >(`${ENV.backendBaseUrl}/api/validateComparableExists`, params)
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }
}
