import {
  ValueAdjustmentGridConfig,
  ValueAdjustmentRowConfig,
} from 'src/app/module/adjustment-grid/interface/adjustment.interface';
import { PrimarySearchFilter, SecondarySearchFilter } from 'src/app/module/order/interface/order.interface';
import { PreviewSection } from 'src/app/module/order/interface/preview.interface';
import { AllOrderType } from './report.interface';

export interface CompanySetting {
  additionalSettings: AdditionalSettings;
  adjustmentRecommendationSettings: AdjustmentRecommendationSettings;
  appraisalReport: AppraisalReport;
  avmReport: AvmReport;
  bpoReport: BPOReport;
  certification: Certification;
  companyKey: string;
  complexityReport: ComplexityReport;
  compsTableSettings?: string[] | null;
  costApproachTableSettings?: string[] | null;
  createdDate: string;
  defaultValues?: DefaultData;
  desktopAppraisalReport: DesktopAppraisalReport;
  evaluationReport: EvaluationReport;
  expiryInDays: number;
  googleMapId: string;
  id: string;
  imageSettings: ImageSettings;
  inspectionInfo: InspectionInfo;
  mcaRedirectOrderTypeList?: string[];
  orderIdType: string;
  outputAllComps: boolean;
  propertyReport: PropertyReport;
  shortTermRentalCompsTableSettings?: string[] | null;
  shortTermRentalReport: ShortTermRentalReport;
  supportConfigs: SupportConfigs;
  updatedDate: string;
  whiteLabelSettings: WhiteLabelSettings;
}

interface DefaultData extends ReportDefaultData {
  ALL: Record<string, unknown>;
}

type ReportDefaultData = Record<AllOrderType, Record<string, unknown>>;

export interface EvaluationReport {
  enabled: boolean;
  includeFloodData: boolean;
  includeBuildingSketch: boolean;
  hideFmv: boolean;
  includeInspectionData: boolean;
  includeEvalCharacteristics: boolean;
  salesComparisonSettings?: string[] | null;
  reportDownloads?: string[] | null;
  reportForms?: string[] | null;
  reportExport: ReportExport;
  previewMenuConfig?: PreviewSection[];
  initialRedirect: string;
  projectInformation: boolean;
  includeComments?: boolean;
  neighborhood?: NeighborhoodOrderPageSetting;
  reconciliation?: ReconciliationOrderPageSetting;
  costApproach?: CostApproach;
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  clientInfo: ClientInfoSettings;
  salesComparison: SalesComparison;
  shortTermRental: ShortTermRental;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface ReportExport {
  enabled: boolean;
}

export interface WhiteLabelSettings {
  aliasName: string;
  propmixLabel: boolean;
  footer: string;
  companyLogo: string;
  verificationMail: string;
  enabled: boolean;
}

export interface ImageSettings {
  labelList?: string[] | null;
  selectAll: boolean;
  enabled: boolean;
}

export interface SupportConfigs {}

export interface DesktopAppraisalReport {
  enabled: boolean;
  reportConfiguration: ReportConfiguration;
  includeFloodData: boolean;
  includeBuildingSketch: boolean;
  hideFmv: boolean;
  includeInspectionData: boolean;
  salesComparisonSettings?: string[] | null;
  reportDownloads?: string[] | null;
  reportForms?: string[] | null;
  previewMenuConfig?: PreviewSection[];
  initialRedirect: string;
  projectInformation: boolean;
  reconciliation: ReconciliationOrderPageSetting;
  costApproach?: CostApproach;
  neighborhood: NeighborhoodOrderPageSetting;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  clientInfo: ClientInfoSettings;
  salesComparison: SalesComparison;
  shortTermRental: ShortTermRental;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface BPOReport {
  enabled: boolean;
  reportConfiguration: ReportConfiguration;
  includeFloodData: boolean;
  includeBuildingSketch: boolean;
  hideFmv: boolean;
  includeInspectionData: boolean;
  salesComparisonSettings?: string[] | null;
  reportDownloads?: string[] | null;
  reportForms?: string[] | null;
  previewMenuConfig?: PreviewSection[];
  initialRedirect: string;
  projectInformation: boolean;
  reconciliation: ReconciliationOrderPageSetting;
  costApproach?: CostApproach;
  neighborhood: NeighborhoodOrderPageSetting;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  clientInfo: ClientInfoSettings;
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  salesComparison: SalesComparison;
  shortTermRental: ShortTermRental;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface SalesComparison {
  valueAdjustmentLayout: ValueAdjustmentGridConfig;
  valueAdjustmentAdditionalFields: ValueAdjustmentRowConfig[];
  secondarySearchFilter: SecondarySearchFilter;
  orderSummary?: OrderSummary | null;
  enabled: boolean;
  primarySearchFilter?: PrimarySearchFilter;
}

export interface ReportConfiguration {
  pdfTitle: string;
}

export interface ComplexityReport {
  enabled: boolean;
  includeFloodData: boolean;
  includeBuildingSketch: boolean;
  hideFmv: boolean;
  includeInspectionData?: boolean;
  reportDownloads?: string[] | null;
  reportExport: ReportExport1;
  salesComparisonSettings?: string[] | null;
  initialRedirect: string;
  projectInformation: boolean;
  includeComments?: boolean;
  neighborhood?: NeighborhoodOrderPageSetting;
  reconciliation?: ReconciliationOrderPageSetting;
  costApproach?: CostApproach;
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  previewMenuConfig?: PreviewSection[];
  clientInfo: ClientInfoSettings;
  salesComparison: SalesComparison;
  shortTermRental: ShortTermRental;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface ShortTermRentalReport {
  enabled: boolean;
  includeFloodData: boolean;
  includeBuildingSketch: boolean;
  hideFmv: boolean;
  reportDownloads?: string[] | null;
  reportConfiguration: ReportConfiguration;
  salesComparisonSettings?: string[] | null;
  initialRedirect: string;
  projectInformation: boolean;
  primarySearchFilter: PrimarySearchFilter;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  includeInspectionData?: boolean;
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  income: ShortTermRentalIncomePageSetting;
  costApproach?: CostApproach;
  previewMenuConfig?: PreviewSection[];
  reconciliation?: ReconciliationOrderPageSetting;
  neighborhood?: NeighborhoodOrderPageSetting;
  clientInfo: ClientInfoSettings;
  shortTermRental: ShortTermRental;
  salesComparison: SalesComparison;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface ShortTermRental {
  valueAdjustmentLayout: ValueAdjustmentGridConfig;
  valueAdjustmentAdditionalFields: ValueAdjustmentRowConfig[];
  secondarySearchFilter: SecondarySearchFilter;
  orderSummary?: OrderSummary | null;
  enabled: boolean;
  primarySearchFilter?: PrimarySearchFilter;
}

export interface CommentConfig {
  key: string;
  enabled: boolean;
  displayText: boolean;
}

export interface ReportExport1 {
  enabled: boolean;
  artifact?: null;
  exportUrl?: null;
  authentication: Authentication;
}

export interface Authentication {
  scheme?: null;
  accessToken?: null;
  authUrl?: null;
  userName?: null;
  password?: null;
  grantType?: null;
  scope?: null;
}

export interface AdjustmentRecommendationSettings {
  propmixAdjustment: boolean;
  evalAdjustment: boolean;
  pairedSalesAdjustment: boolean;
  regressionAdjustment: boolean;
}

export interface AdditionalSettings {
  includeComments: boolean;
  includeMarketConditionComments: boolean;
  includeClientInfo: boolean;
  includeValuationComments: boolean;
  includeSupportingDocs: boolean;
  includeValidationSettings: boolean;
}

export interface AppraisalReport {
  enabled: boolean;
  reportConfiguration: ReportConfiguration;
  reportForms?: string[] | null;
  includeFloodData: boolean;
  includeBuildingSketch: boolean;
  hideFmv: boolean;
  includeInspectionData?: boolean;
  reportDownloads?: string[] | null;
  reportExport: ReportExport2;
  salesComparisonSettings?: string[] | null;
  previewMenuConfig?: PreviewSection[];
  initialRedirect: string;
  projectInformation: boolean;
  includeComments?: boolean;
  neighborhood?: NeighborhoodOrderPageSetting;
  reconciliation?: ReconciliationOrderPageSetting;
  costApproach?: CostApproach;
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  clientInfo: ClientInfoSettings;
  salesComparison: SalesComparison;
  shortTermRental: ShortTermRental;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface ReportExport2 {
  enabled: boolean;
  artifact: string;
  exportUrl: string;
  authentication: Authentication1;
}

export interface Authentication1 {
  scheme: string;
  accessToken?: null;
  authUrl: string;
  userName: string;
  password: string;
  grantType?: null;
  scope?: null;
}

export interface AvmReport {
  enabled: boolean;
  reportForms?: string[] | null;
  initialRedirect: string;
  projectInformation: boolean;
  includeComments?: boolean;
  includeInspectionData?: boolean;
  neighborhood?: NeighborhoodOrderPageSetting;
  reconciliation?: ReconciliationOrderPageSetting;
  costApproach?: CostApproach;
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  previewMenuConfig?: PreviewSection[];
  reportDownloads?: string[] | null;
  clientInfo: ClientInfoSettings;
  salesComparison: SalesComparison;
  shortTermRental: ShortTermRental;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface PropertyReport {
  enabled: boolean;
  includeFloodData: boolean;
  includeBuildingSketch: boolean;
  hideFmv: boolean;
  salesComparisonSettings?: string[] | null;
  reportDownloads?: string[] | null;
  initialRedirect: string;
  projectInformation: boolean;
  includeInspectionData?: boolean;
  neighborhood?: NeighborhoodOrderPageSetting;
  reconciliation?: ReconciliationOrderPageSetting;
  costApproach?: CostApproach;
  allowUnauditedDownload?: boolean;
  allowUnsignedDownload?: boolean;
  additionalCommentsConfig?: {
    enabled: boolean;
    commentConfigList: CommentConfig[];
  };
  previewMenuConfig?: PreviewSection[];
  clientInfo: ClientInfoSettings;
  salesComparison: SalesComparison;
  shortTermRental: ShortTermRental;
  longTermRental: any;
  orderContext: OrderContextBarSettings;
  certificationPage: CertificationSettings;
}

export interface NeighborhoodSetting {
  singleIndicatorChart: SingleIndicatorChart[];
  groupIndicatorChart: GroupIndicatorChart[];
  marketField: MarketField[];
}

export enum SingleIndicatorChart {
  medianSoldPrice = 'medianSoldPrice',
  avgDOM = 'avgDOM',
  medianSoldPricePerSqft = 'medianSoldPricePerSqft',
  inventory = 'inventory',
  medianListPriceToSoldPrice = 'medianListPriceToSoldPrice',
  inventoryAbsorptionRate = 'inventoryAbsorptionRate',
  medianListPrice = 'medianListPrice',
}

export enum GroupIndicatorChart {
  priceTrend = 'priceTrend',
  // yearBuilt = 'yearBuilt',
  medianSoldToListPrice = 'medianSoldToListPrice',
  absorptionRate = 'absorptionRate',
  medianDOM = 'medianDOM',
}

export enum MarketField {
  boundary = 'boundary',
  description = 'description',
  isSimilarToNeighborhood = 'isSimilarToNeighborhood',
  propertyValue = 'propertyValue',
  demandSupply = 'demandSupply',
  marketingTime = 'marketingTime',
  exposureTime = 'exposureTime',
  location = 'location',
  buildUp = 'buildUp',
  growth = 'growth',
  employment = 'employment',
  population = 'population',
}

export interface InspectionInfo {
  inspectionProviders?: string[] | null;
  derivedFields?: string[] | null;
}

export interface Certification {
  enabled: boolean;
  certificationList?: string[];
  disclosureData?: string;
  includeDisclosure?: boolean;
}

export interface Reconciliation {
  includeHighestAndBestUseComments: boolean;
  includeAsRepairedalue: boolean;
  includeSiteValue: boolean;
  includeDefaultAsIsValue: boolean;
  enabled: boolean;
  includeHoaComments: boolean;
}

export interface ReconciliationOrderPageSetting {
  enabled: boolean;
  appraisalSummary: {
    enabled: boolean;
    displayValue: string | null;
    opinionOfMarketValue: {
      enabled: boolean;
      displayValue: string | null;
      marketValue: {
        enabled: boolean;
        displayValue: string | null;
      };
      marketValueLow: {
        enabled: boolean;
        displayValue: string | null;
      };
      marketValueHigh: {
        enabled: boolean;
        displayValue: string | null;
      };
    };
    marketValueCondition: {
      enabled: boolean;
      displayValue: string | null;
      marketValueConditionSelect: {
        enabled: boolean;
        displayValue: string | null;
      };
    };
    subjectToValues: {
      enabled: boolean;
      displayValue: string | null;
      asIs: {
        enabled: boolean;
        displayValue: string | null;
      };
      afterRepair: {
        enabled: boolean;
        displayValue: string | null;
      };
      afterImprovement: {
        enabled: boolean;
        displayValue: string | null;
      };
      afterInspection: {
        enabled: boolean;
        displayValue: string | null;
      };
    };
    siteValue: {
      enabled: boolean;
      displayValue: string | null;
    };
    quickSaleValue: {
      enabled: boolean;
      displayValue: string | null;
    };
    costToCure: {
      enabled: boolean;
      displayValue: string | null;
    };
  };
  investmentInformation: {
    enabled: boolean;
    displayValue: string | null;
    acquisitionCost: {
      enabled: boolean;
      displayValue: string | null;
    };
    repairCost: {
      enabled: boolean;
      displayValue: string | null;
    };
    proposedCondition: {
      enabled: boolean;
      displayValue: string | null;
    };
    glaChange: {
      enabled: boolean;
      displayValue: string | null;
    };
  };
  comments: {
    enabled: boolean;
    displayValue: string | null;
    valueConditionStatement: {
      enabled: boolean;
      displayValue: string | null;
    };
    reconciliationComments: {
      enabled: boolean;
      displayValue: string | null;
    };
    hoaComments: {
      enabled: boolean;
      displayValue: string | null;
    };
    highestBestUseComments: {
      enabled: boolean;
      displayValue: string | null;
    };
  };
}

export interface NeighborhoodOrderPageSetting extends NeighborhoodSetting {
  enabled: boolean;
  sectionConfigList: SectionConfig[];
}

export interface SectionConfig {
  sectionId: string;
  enabled: boolean;
  displayValue: string | null;
  sectionConfigList?: SectionConfig[];
}

export interface CostApproach {
  enabled: boolean;
  secondarySearchFilter: SecondarySearchFilter;
  primarySearchFilter?: PrimarySearchFilter;
}

export interface ShortTermRentalIncomePageSetting {
  enabled: boolean;
  potentialGrossIncome: {
    enabled: boolean;
    displayValue: string | null;
    dailyRentValue: {
      enabled: boolean;
      displayValue: string | null;
    };
    calculatedDailyRent: {
      enabled: boolean;
      displayValue: string | null;
    };
    monthlyRentValue: {
      enabled: boolean;
      displayValue: string | null;
    };
    annualRentValue: {
      enabled: boolean;
      displayValue: string | null;
    };
    otherRevenue: {
      enabled: boolean;
      displayValue: string | null;
    };
    otherRevenueDescription: {
      enabled: boolean;
      displayValue: string | null;
    };
  };
  effectiveGrossIncome: {
    enabled: boolean;
    displayValue: string | null;
    occupancyRate: {
      enabled: boolean;
      displayValue: string | null;
    };
    nonOccupancyLoss: {
      enabled: boolean;
      displayValue: string | null;
    };
  };
}

export enum MarketValueCondition {
  asIs = 'asIs',
  afterImprovement = 'afterImprovement',
  afterRepair = 'afterRepair',
  afterInspection = 'afterInspection',
}

export interface ClientInfoSettings {
  enabled: boolean;
  ownerName: {
    enabled: boolean;
    displayValue: string | null;
    isRequired: boolean;
  };
  borrowerName: {
    enabled: boolean;
    displayValue: string | null;
    isRequired: boolean;
  };
  clientName: {
    enabled: boolean;
    displayValue: string | null;
    isRequired: boolean;
  };
  clientFileId: {
    enabled: boolean;
    displayValue: string | null;
    isRequired: boolean;
  };
  intendedUseOfValuation: {
    enabled: boolean;
    displayValue: string | null;
    isRequired: boolean;
  };
  assignmentObjective: {
    enabled: boolean;
    displayValue: string | null;
    isRequired: boolean;
  };
}

export interface OrderSummary {
  enabled: boolean;
  clientName: {
    enabled: boolean;
    displayValue: string | null;
  };
  clientFileId: {
    enabled: boolean;
    displayValue: string | null;
  };
  ownerName: {
    enabled: boolean;
    displayValue: string | null;
  };
  borrowerName: {
    enabled: boolean;
    displayValue: string | null;
  };
  assignmentObjective: {
    enabled: boolean;
    displayValue: string | null;
  };
  intendedUseOfValuation: {
    enabled: boolean;
    displayValue: string | null;
  };
  effectiveDate: {
    enabled: boolean;
    displayValue: string | null;
  };
}

export interface OrderContextBarSettings {
  gla: {
    enabled: boolean;
    displayValue: string | null;
  };
  lotSize: {
    enabled: boolean;
    displayValue: string | null;
  };
  beds: {
    enabled: boolean;
    displayValue: string | null;
  };
  baths: {
    enabled: boolean;
    displayValue: string | null;
  };
  lastSale: {
    enabled: boolean;
    displayValue: string | null;
  };
  salesApproach: {
    enabled: boolean;
    displayValue: string | null;
  };
  costApproach: {
    enabled: boolean;
    displayValue: string | null;
  };
  shortTermRental: {
    enabled: boolean;
    displayValue: string | null;
  };
  profetEstimatedValue: {
    enabled: boolean;
    displayValue: string | null;
  };
  interactiveValue: {
    enabled: boolean;
    displayValue: string | null;
  };
  marketValue: {
    enabled: boolean;
    displayValue: string | null;
  };
  marketType: {
    enabled: boolean;
    displayValue: string | null;
  };
  location: {
    enabled: boolean;
    displayValue: string | null;
  };
}

export interface CertificationSettings {
  enabled: boolean;
  certification: {
    enabled: boolean;
    displayValue: string;
    certificationList: string[];
  };
  disclosure: {
    enabled: boolean;
    displayValue: string;
    disclosureContent: string;
  };
  appraiserSignature: {
    enabled: boolean;
    displayValue: string | null;
  };
  companyName: {
    enabled: boolean;
    displayValue: string | null;
  };
  appraiserName: {
    enabled: boolean;
    displayValue: string | null;
  };
  dateOfSignature: {
    enabled: boolean;
    displayValue: string | null;
  };
  companyAddress: {
    enabled: boolean;
    displayValue: string | null;
  };
  licenseNumber: {
    enabled: boolean;
    displayValue: string | null;
  };
  contactPhone: {
    enabled: boolean;
    displayValue: string | null;
  };
}
