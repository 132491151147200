<h1 mat-dialog-title class="text-center mb-0 pb-0">{{ dialogData?.title }}</h1>
<div mat-dialog-content class="text-center">
  {{ dialogData?.description }}
</div>
<div mat-dialog-actions class="justify-content-center">
  <button mat-stroked-button mat-dialog-close color="primary" (click)="onClose(false)">
    {{ dialogData?.buttonLabel[0] }}
  </button>
  <button mat-flat-button mat-dialog-close color="accent" cdkFocusInitial (click)="onClose(true)">
    {{ dialogData?.buttonLabel[1] }}
  </button>
</div>
