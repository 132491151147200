import { Pipe, PipeTransform } from '@angular/core';
import { DynamicPipe } from 'src/app/shared/pipes/dynamic.pipe';
import { SelectedSourceDataPipe } from 'src/app/shared/pipes/selected-source-data.pipe';
import { PropertyDetail } from '../interface/property.interface';

@Pipe({
  name: 'bathroomDecimal',
  standalone: true,
})
export class BathroomDecimalPipe implements PipeTransform {
  constructor(
    private selectedSourceDataPipe: SelectedSourceDataPipe,
    private dynamicPipe: DynamicPipe
  ) {}

  /**
   *
   * @param value The property details data
   * @param forDisplay Whether the value is for display or not
   * @returns The bathrooms full & half value in decimal format
   */
  transform(
    value: PropertyDetail | null | undefined,
    dataTransform: boolean = true,
    forDisplay: boolean = true
  ): string | null {
    if (!value) return forDisplay ? '--' : null;

    const fullBath =
      (this.dynamicPipe.transform(
        dataTransform
          ? this.selectedSourceDataPipe.transform(value?.BathroomsFullCalculated)
          : value?.BathroomsFullCalculated,
        'number'
      ) as number) ?? 0;
    const halfBath =
      (this.dynamicPipe.transform(
        dataTransform
          ? this.selectedSourceDataPipe.transform(value?.BathroomsHalfCalculated)
          : value?.BathroomsHalfCalculated,
        'number'
      ) as number) ?? 0;

    if (!fullBath && !halfBath) return forDisplay ? '--' : null;
    return halfBath ? `${fullBath}.${halfBath}` : `${fullBath}`;
  }
}
