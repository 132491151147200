import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY, catchError, from, switchMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/module/auth/service/auth.service';
import { AuthToken } from 'src/app/module/auth/state/auth.state';
import { environment as ENV } from 'src/environments/environment';

export const authTokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService = inject(AuthService)
) => {
  return from(authService.getAuthToken()).pipe(
    switchMap((auth: AuthToken | null) => {
      let modifiedRequest = req;

      const authenticatedUrls = [ENV.backendBaseUrl, ENV.portal.backendBaseUrl, ENV.workflow.backendBaseUrl];

      if (authenticatedUrls.some((url) => req.url.startsWith(url))) {
        modifiedRequest = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${auth?.accessToken ?? ''}`),
        });
      }

      return next(modifiedRequest).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            authService.logout();
            return EMPTY;
          }
          return throwError(() => error as HttpEvent<unknown>);
        })
      );
    })
  );
};
