import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IApproachState } from 'src/app/module/order/interface/approach-state.interface';
import { IncomeInfo } from 'src/app/module/short-term-rental/interface/short-term-rental.interface';
import { ComparableTableDataSource, PropertyMap, SecondarySearchFilter } from '../../order/interface/order.interface';

@Injectable({
  providedIn: 'root',
})
export class ShortTermRentalState implements IApproachState {
  constructor() {}

  /**
   * Stores the criteria to be used to get comparables.
   * This state should be updated every time a sub-filter, pagination or sort happens on the comparables.
   * This will trigger the /getComparables API based on the saved criteria.
   */
  private _secondarySearchFilterSub$ = new BehaviorSubject<SecondarySearchFilter | null>(null);
  secondarySearchFilter$ = this._secondarySearchFilterSub$.asObservable();

  get secondarySearchFilter(): SecondarySearchFilter | null {
    return this._secondarySearchFilterSub$.value;
  }

  set secondarySearchFilter(order: SecondarySearchFilter | null) {
    this._secondarySearchFilterSub$.next(order);
  }

  /** Stores a list of comparable properties an active order */
  private _comparableListSub$ = new BehaviorSubject<Array<ComparableTableDataSource>>([]);
  comparableList$ = this._comparableListSub$.asObservable();

  get comparableList(): Array<ComparableTableDataSource> {
    return this._comparableListSub$.value;
  }

  set comparableList(order: Array<ComparableTableDataSource>) {
    this._comparableListSub$.next(order);
  }

  /** Stores full list of selected comparable properties an active order */
  private _selectedComparableMapSub$ = new BehaviorSubject<PropertyMap>({});
  selectedComparableMap$ = this._selectedComparableMapSub$.asObservable();

  get selectedComparableMap(): PropertyMap {
    return this._selectedComparableMapSub$.value;
  }

  set selectedComparableMap(value: PropertyMap) {
    this._selectedComparableMapSub$.next(value);
  }

  /**
   * Stores total length of comparable properties in an active order
   * This is to be mainly used for pagination.
   */
  private _comparableListLengthSub$ = new BehaviorSubject<number>(0);
  comparableListLength$ = this._comparableListLengthSub$.asObservable();

  get comparableListLength(): number {
    return this._comparableListLengthSub$.value;
  }

  set comparableListLength(length: number) {
    this._comparableListLengthSub$.next(length);
  }

  private _incomeInfoSub$ = new BehaviorSubject<IncomeInfo>({} as IncomeInfo);
  incomeInfo$ = this._incomeInfoSub$.asObservable();

  get incomeInfo(): IncomeInfo {
    return this._incomeInfoSub$.value;
  }

  set incomeInfo(value: IncomeInfo) {
    this._incomeInfoSub$.next(value);
  }
}
