import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, finalize, map, of, tap } from 'rxjs';
import { SelectOptionData } from 'src/app/shared/interface/profet-control-widget.interface';
import { AllOrderType, ValuationOrderSubType } from 'src/app/shared/interface/report.interface';
import { ProgressLoaderService } from 'src/app/shared/services/progress-loader.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CompanyState } from 'src/app/shared/state/company.state';
import { OrderConfig } from '../../order/config/order.config';
import { RedirectUrlApiRequest, RedirectUrlApiResponse } from '../../order/interface/order.interface';
import { OrderState } from '../../order/state/order.state';
import { OrderListApi } from '../api/order-list.api';
import { OrderListConfig } from '../config/order-list.config';
import { OrderListFilter, WorkflowPanel, WorkflowPanelUser } from '../interface/order-list.interface';
import { OrderListState } from '../state/order-list.state';

@Injectable({
  providedIn: 'root',
})
export class OrderListService {
  constructor(
    private orderListApi: OrderListApi,
    private orderListState: OrderListState,
    private companyState: CompanyState,
    private snackBarService: SnackBarService,
    private orderState: OrderState,
    private progressLoaderService: ProgressLoaderService
  ) {}

  getOrderList(): Observable<void> {
    const nonNullOrderFilter: Partial<OrderListFilter> = {};
    let orderFilter: OrderListFilter = { ...this.orderListState.orderFilter };

    if (Object.values(ValuationOrderSubType).includes(orderFilter.orderType as any)) {
      orderFilter = {
        ...orderFilter,
        orderType: null,
        reportType: orderFilter.orderType as ValuationOrderSubType,
        companyKey: this.companyState.companySetting.companyKey,
      };
    } else {
      orderFilter = { ...orderFilter, reportType: undefined, companyKey: this.companyState.companySetting.companyKey };
    }
    Object.entries(orderFilter).forEach(([key, value]) => {
      if (value) nonNullOrderFilter[key as keyof OrderListFilter] = value;
    });

    this.progressLoaderService.show().message('Fetching Orders');
    return this.orderListApi.getOrderList(nonNullOrderFilter).pipe(
      map((res) => {
        this.orderListState.orderList = res;
      }),
      catchError(() => EMPTY),
      finalize(() => this.progressLoaderService.hide())
    );
  }

  getUserList(companyId: string): Observable<void> {
    return this.orderListApi.getUserList(companyId).pipe(
      map((res) => {
        this.orderListState.userList = res;
      })
    );
  }

  copyOrder(orderId: string, newOrderId: string): Observable<void> {
    return this.orderListApi.copyOrder(orderId, newOrderId).pipe(
      tap(() => this.snackBarService.open('success', 'Order duplicated successfully.')),
      catchError(() => {
        this.snackBarService.open('error', 'Could not duplicate order. Please try again later.');
        return of();
      })
    );
  }

  transferOrder(orderId: string, username: string, companyId?: string): Observable<void> {
    return this.orderListApi.transferOrder(orderId, username, companyId).pipe(
      tap(() => this.snackBarService.open('success', 'Order transferred successfully.')),
      catchError(() => {
        this.snackBarService.open('error', 'Could not transfer order. Please try again later.');
        return of();
      })
    );
  }

  resetOrderFilter(): void {
    this.orderListState.orderFilter = {
      companyKey: this.companyState.companySetting.companyKey,
      ...OrderListConfig.defaultFilter,
    };
  }

  /**Get all enabled order type under a specific company */
  getEnabledOrderTypes() {
    const enabledOrderTypeList: SelectOptionData[] = OrderConfig.orderType.filter((type) => {
      if (type.children) {
        return type.children.filter((c) => {
          if (this.companyState.companySetting?.[c.key as ValuationOrderSubType]?.enabled) return true;
          else return false;
        });
      } else if (this.companyState.companySetting?.[type.key as AllOrderType]?.enabled) return true;
      else return false;
    });
    return enabledOrderTypeList;
  }

  getOrderRedirectionUrl(param: RedirectUrlApiRequest): Observable<string> {
    this.progressLoaderService.show().message('Getting Redirection Info');
    return this.orderListApi.getOrderRedirectionUrl(param).pipe(
      map((res: RedirectUrlApiResponse) => {
        this.progressLoaderService.hide();
        return res.redirectURL
          ? param.action == 'create'
            ? res.redirectURL
            : res.isOrderExistInMCA
              ? res.redirectURL
              : ''
          : '';
      }),
      catchError(() => {
        this.progressLoaderService.hide();
        this.snackBarService.open('error', 'Failed to redirect.Please try again later');
        return of('');
      })
    );
  }

  /** Get the list of Workflow Panels for the current user's company */
  getPanelList(): Observable<WorkflowPanel[]> {
    return this.orderListApi.getWorkflowPanelList();
  }

  /** Get the list of users in a specific Workflow Panel */
  getPanelUsers(panelId: number): Observable<WorkflowPanelUser[]> {
    return this.orderListApi.getPanelUsers(panelId);
  }
}
