import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, map, of, switchMap, takeUntil, tap } from 'rxjs';
import {
  SearchMode,
  SearchPropertyQuery,
  SearchedPropertyData,
  SearchedPropertyEmitData,
} from 'src/app/module/new-order/interface/new-order.interface';
import { NewOrderService } from 'src/app/module/new-order/service/new-order.service';
import { NewOrderState } from 'src/app/module/new-order/state/new-order.state';
import { CountyFips, MLSBoard } from 'src/app/module/order/interface/order.interface';
import { OrderService } from 'src/app/module/order/service/order.service';
import { SelectOptionData } from 'src/app/shared/interface/profet-control-widget.interface';
import { ProgressLoaderService } from '../../services/progress-loader.service';
import { AppState } from '../../state/app.state';

@Component({
  selector: 'app-search-property',
  templateUrl: './search-property.component.html',
  styleUrls: ['./search-property.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPropertyComponent implements OnInit, OnDestroy {
  onDestroyNotifier$ = new Subject<void>();
  allStatCountyList?: CountyFips;
  // countyList: { key: string; displayValue: string }[] = [];
  searchByAddressFormDisable: boolean = true;
  mlsBoardList: SelectOptionData[] = [];
  isMLSInProgress: boolean = false;
  searchMode!: SearchMode;
  searchByAutoCompleteForm: FormGroup = this._fb.group({
    streetNumber: [],
    streetName: [],
    unitNumber: [],
    city: [],
    state: [],
    zip: [],
    address: [],
  });

  searchByAddressForm: FormGroup = this._fb.group({
    streetNumber: [],
    streetName: [],
    unitNumber: [],
    city: [],
    state: [],
    zip: [],
    fipsCode: [],
  });

  searchByMlsForm: FormGroup = this._fb.group({
    mlsNumber: [null, [Validators.required]],
    mlsBoard: [{ value: null, disabled: true }, [Validators.required]],
    state: [null, [Validators.required]],
  });

  searchByParcelForm: FormGroup = this._fb.group({
    parcelNumber: [null, [Validators.required]],
    state: [null, [Validators.required]],
    zip: [null, [Validators.required]],
  });

  searchByOwnerForm: FormGroup = this._fb.group({
    ownerName: [null, [Validators.required]],
    zip: [null, [Validators.required]],
  });

  pageNumber: number = 0;
  pageSize: number = 10;
  propertySearchFormValues!: any;

  @Input() scope!: string;

  @Input() searchPropertyQuery!: SearchPropertyQuery | null;

  @Output() propertySearch = new EventEmitter<SearchedPropertyEmitData>();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _fb: FormBuilder,
    public _route: ActivatedRoute,
    private newOrderService: NewOrderService,
    private newOrderState: NewOrderState,
    private orderService: OrderService,
    private progressLoaderService: ProgressLoaderService,
    public appState: AppState
  ) {}

  ngOnInit() {
    this.newOrderState.searchPropertyPagination$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((pageData) => {
      if (pageData) {
        this.progressLoaderService.show().message('Searching for Property');
        this.pageNumber = pageData.pageIndex || 0;
        this.pageSize = pageData.pageSize || 10;
        this.onPropertySearch();
      }
    });

    this.orderService
      .getAllStateCountyFips()
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((data) => {
        this.allStatCountyList = data;
      });

    /**
     * Commented out because we replaced County with ZIP in Parcel Number search
     */
    // this.searchByParcelForm
    //   .get('state')
    //   ?.valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
    //   .subscribe((value) => {
    //     this.countyList = [];
    //     this.searchByParcelForm.get('fipsCode')?.patchValue(null);

    //     if (value) {
    //       const list: { key: string; displayValue: string }[] = [];

    //       Object.entries(this.allStatCountyList?.[value] ?? {})?.forEach(([key, value]) => {
    //         const displayValue = value.toLowerCase().replace(/(^|\s)\S/g, function (firstLetter: string) {
    //           return firstLetter.toUpperCase();
    //         });
    //         list.push({ key: key, displayValue: `${displayValue} (${key})` });
    //       });

    //       list.sort((a, b) => (a.displayValue < b.displayValue ? -1 : 1));
    //       this.countyList = [...list];
    //       this.searchByParcelForm.get('fipsCode')?.enable();
    //     } else {
    //       this.searchByParcelForm.get('fipsCode')?.disable();
    //     }
    //     this._cdr.detectChanges();
    //   });

    this.searchByAddressForm.valueChanges.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((value) => {
      if ((value.streetNumber || value.streetName) && (value.zip || (value.city && value.state) || value.fipsCode)) {
        this.searchByAddressFormDisable = false;
      } else {
        this.searchByAddressFormDisable = true;
      }
    });

    this.searchByMlsForm
      .get('state')
      ?.valueChanges.pipe(
        takeUntil(this.onDestroyNotifier$),
        tap(() => {
          this.searchByMlsForm.get('mlsBoard')?.patchValue(null);
          this.searchByMlsForm.get('mlsBoard')?.disable();
        }),
        switchMap((value) => {
          if (value) {
            this.isMLSInProgress = true;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            // return this.orderService.getMLSBoards({ state: value }).pipe(
            //   takeUntil(this.onDestroyNotifier$),
            //   map((data: MLSBoard[]) => {
            //     this.isMLSInProgress = false;
            //     const list: SelectOptionData[] = [];
            //     data?.forEach((item: MLSBoard) => {
            //       list.push({ key: item.MLSBoard, displayValue: item.MLSBoard, subText: item.MLSName });
            //     });
            //     return list.sort((a, b) => ((a.displayValue as string) < (b.displayValue as string) ? -1 : 1));
            //   })
            // );
            return this.getMlsBoards(value);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((list) => {
        if (list?.length) {
          this.mlsBoardList = [...list];
          this.searchByMlsForm.get('mlsBoard')?.enable();
        }
        this._cdr.detectChanges();
      });

    if (this.searchPropertyQuery) {
      switch (this.searchPropertyQuery.searchMode) {
        case 'address':
          this.searchByAddressForm.patchValue(this.searchPropertyQuery?.searchData ?? {});
          if (!this.newOrderState.searchPropertyQuery?.searchedProperty)
            this.initiateSearch(this.searchByAddressForm, 'address');
          break;

        case 'mls':
          this.searchByMlsForm.patchValue(this.searchPropertyQuery?.searchData ?? {}, { emitEvent: false });

          this.getMlsBoards(this.searchByMlsForm.get('state')?.value)
            .pipe(takeUntil(this.onDestroyNotifier$))
            .subscribe((list) => {
              if (list?.length) {
                this.mlsBoardList = [...list];
                this.searchByMlsForm.get('mlsBoard')?.enable();
              }

              if (!this.newOrderState.searchPropertyQuery?.searchedProperty)
                this.initiateSearch(this.searchByMlsForm, 'mls');
              this._cdr.detectChanges();
            });

          break;

        case 'owner':
          this.searchByOwnerForm.patchValue(this.searchPropertyQuery?.searchData ?? {});
          if (!this.newOrderState.searchPropertyQuery?.searchedProperty)
            this.initiateSearch(this.searchByOwnerForm, 'owner');
          break;

        case 'parcel':
          this.searchByParcelForm.patchValue(this.searchPropertyQuery?.searchData ?? {});
          if (!this.newOrderState.searchPropertyQuery?.searchedProperty)
            this.initiateSearch(this.searchByParcelForm, 'parcel');
          break;
      }
    }
  }

  getMlsBoards(value: string): Observable<any> {
    if (value) {
      this.isMLSInProgress = true;
      return this.orderService.getMLSBoards({ state: value }).pipe(
        takeUntil(this.onDestroyNotifier$),
        map((data: MLSBoard[]) => {
          this.isMLSInProgress = false;
          const list: SelectOptionData[] = [];
          data?.forEach((item: MLSBoard) => {
            list.push({ key: item.MLSBoard, displayValue: item.MLSBoard, subText: item.MLSName });
          });
          return list.sort((a, b) => ((a.displayValue as string) < (b.displayValue as string) ? -1 : 1));
        })
      );
    } else {
      return of([]);
    }
  }

  onPropertySearch() {
    let reqParams: any = {
      StreetNumber: this.propertySearchFormValues?.streetNumber || '',
      StreetName: this.propertySearchFormValues?.streetName
        ? this.propertySearchFormValues?.unitNumber
          ? `${this.propertySearchFormValues.streetName} #${this.propertySearchFormValues.unitNumber}`
          : this.propertySearchFormValues.streetName
        : '',
      City: this.propertySearchFormValues?.city || '',
      State: this.propertySearchFormValues?.state || '',
      zip: this.propertySearchFormValues?.zip || '',
      fipscode: this.propertySearchFormValues?.fipsCode || '',

      pagenumber: this.pageNumber + 1,
      pagesize: this.pageSize,
      AdditionalSources: 'Assessor,HUDLISTING',

      ParcelNumber: this.propertySearchFormValues?.parcelNumber || '',
      OwnerName: this.propertySearchFormValues?.ownerName || '',

      MlsBoard: this.propertySearchFormValues?.mlsBoard || '',
      MlsListingNumber: this.propertySearchFormValues?.mlsNumber || '',
    };

    if (this.scope == 'COMPARABLE')
      reqParams = { ...reqParams, ListingsOnly: false, AdditionalSources: 'Assessor', authType: 'dynamic' };

    this.newOrderService
      .searchProperty(reqParams, this.searchMode)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((res: SearchedPropertyData) => {
        this.progressLoaderService.hide();
        this.propertySearch.emit({
          property: res,
          searchFormData: { searchMode: this.searchMode, searchData: this.propertySearchFormValues },
        });
      });
  }

  onAddressSelected(event: any) {
    this.searchByAutoCompleteForm.reset();
    this.searchByAutoCompleteForm.patchValue(event);
    this.initiateSearch(this.searchByAutoCompleteForm, 'autoAddress');
  }

  initiateSearch(form: FormGroup, mode: SearchMode) {
    this.progressLoaderService.show().message('Searching for Property');
    this.searchMode = mode;
    this.propertySearchFormValues = form.value;

    this.newOrderState.searchPropertyPagination = {
      pageSize: 10,
      pageIndex: 0,
    };
  }

  ngOnDestroy(): void {
    this.newOrderState.searchPropertyPagination = null;
    this.progressLoaderService.hide();
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}
