import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, endWith, map, of, repeat, startWith, switchMap, takeWhile } from 'rxjs';
import { ValuationOrderSubType } from 'src/app/shared/interface/report.interface';
import { SelectedSourceDataPipe } from 'src/app/shared/pipes/selected-source-data.pipe';
import { ProxyService } from 'src/app/shared/services/proxy.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CommonService } from 'src/app/shared/utility/common.service';
import { DocumentPreviewApi } from '../../document-preview/api/document-preview.api';
import { CostApproachState } from '../../order/state/cost-approach.state';
import { OrderState } from '../../order/state/order.state';
import { SalesApproachState } from '../../order/state/sales-approach.state';
import { ShortTermRentalState } from '../../short-term-rental/state/short-term-rental.state';
import { SyncState } from '../../sync/state/sync.state';
import { PropertyApi } from '../api/property.api';
import {
  ExtendedPropertyDetail,
  GenerateListingSheetApiRequest,
  PropertyAttribute,
  PropertyDataApiRequest,
  PropertyDetail,
  PropertyImage,
  PropertyViewApiRequest,
  StandardStatusType,
} from '../interface/property.interface';
import { PropertyState } from '../state/property.state';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(
    private costApproachState: CostApproachState,
    private documentPreviewApi: DocumentPreviewApi,
    private orderState: OrderState,
    private propertyApi: PropertyApi,
    private propertyState: PropertyState,
    private proxyService: ProxyService,
    private salesApproachState: SalesApproachState,
    private selectedSourceData: SelectedSourceDataPipe,
    private selectedSourceDataPipe: SelectedSourceDataPipe,
    private snackBarService: SnackBarService,
    private syncState: SyncState,
    private shortTermRentalState: ShortTermRentalState,
    private commonService: CommonService
  ) {}

  /**
   * This fn will be called once per order to get the property view layout.
   * The data will be available in the propertyState to read, elsewhere in the app.
   */
  fetchPropertyViewLayout(reportType: ValuationOrderSubType): Observable<boolean> {
    const requestBody: PropertyViewApiRequest = {
      pageName: 'propertyDetails',
      reportType,
    };
    return this.propertyApi.getActivePropertyPageView(requestBody).pipe(
      map((res) => {
        this.propertyState.propertyPageLayout = res.sectionList;
        return false;
      }),
      catchError(() => {
        this.propertyState.propertyPageLayout = [];
        return of(false);
      })
    );
  }

  /**
   * This fn will be called once per order to get the subject property's details.
   */
  fetchSubjectPropertyDetail(
    reportType: ValuationOrderSubType,
    propertyId?: string,
    listingId?: string
  ): Observable<boolean> {
    return this._fetchPropertyData(reportType, propertyId ?? '', listingId).pipe(
      map((data) => {
        // this.propertyState.subjectPropertyView = view.sectionList;
        this.propertyState.subjectPropertyData = data;
        this.syncState.syncedSubjectPropertyData = data;
        if (data.id == this.propertyState.activePropertyData?.id) this.propertyState.activePropertyData = data;

        return false;
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  /**
   * Use this fn to fetch the details of any property in an order.
   * The property view and data will be stored in propertyState.
   */
  fetchPropertyDetail(
    reportType: ValuationOrderSubType,
    propertyId: string,
    listingId?: string,
    pmxProperty?: boolean
  ): Observable<boolean> {
    return this._fetchPropertyData(reportType, propertyId, listingId, pmxProperty).pipe(
      map((data) => {
        // this.propertyState.activePropertyView = view.sectionList;
        this.propertyState.activePropertyData = data;
        return false;
      }),
      startWith(true),
      catchError(() => {
        // this.propertyState.activePropertyView = [];
        this.propertyState.activePropertyData = null;

        return of(false);
      })
    );
  }

  /**
   * Use this fn to fetch asynchronous attributes of a property
   */
  fetchPropertyDetailForAsyncStatus(
    reportType: ValuationOrderSubType,
    propertyId: string,
    listingId?: string,
    pmxProperty?: boolean,
    responseKeys?: PropertyAttribute[]
  ): Observable<any> {
    return this._fetchPropertyData(reportType, propertyId, listingId, pmxProperty, responseKeys).pipe(
      map((data) => data),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Use this fn to fetch the details of any property history in an order.
   * The property history view and data will be stored in propertyState.
   */
  fetchHistoryPropertyDetail(
    reportType: ValuationOrderSubType,
    propertyId: string,
    listingId: string
  ): Observable<boolean> {
    return this._fetchPropertyData(reportType, propertyId, listingId).pipe(
      map((data) => {
        this.propertyState.activePropertyHistoryData = data;
        return false;
      }),
      startWith(true),
      catchError(() => {
        this.propertyState.activePropertyHistoryData = null;
        return of(false);
      })
    );
  }

  public getCurrentStatusAndPrice(property: PropertyDetail): { status: StandardStatusType; price: number } {
    const currentStatus: StandardStatusType = (this.selectedSourceData.transform(property.StandardStatus) ??
      property.StandardStatus) as StandardStatusType;

    let currentPrice = 0;

    if ([StandardStatusType.active, StandardStatusType.activeUC, StandardStatusType.pending].includes(currentStatus))
      currentPrice = Number(this.selectedSourceData.transform(property.ListPrice) ?? property.ListPrice);
    else if (currentStatus == StandardStatusType.sold) {
      currentPrice = Number(this.selectedSourceData.transform(property.ClosePrice) ?? property.ClosePrice);
    } else if (
      [StandardStatusType.canceled, StandardStatusType.expired, StandardStatusType.delisted].includes(currentStatus)
    )
      currentPrice = Number(
        this.selectedSourceData.transform(property.ClosePrice ?? property.LastSalePrice) ??
          property.ClosePrice ??
          property.LastSalePrice
      );

    return {
      status: currentStatus,
      price: currentPrice,
    };
  }

  getActivePropertyImages(): Observable<Array<PropertyImage>> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.propertyState.activePropertyData$.pipe(
      map((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.selectedSourceData.transform(res?.imageWithLabels) || [];
      })
    );
  }

  /**This function is used to upload files and  */
  addActivePropertyImages(propertyId: string, fileList: FileList): Observable<boolean> {
    const activePropertyData = this.propertyState.activePropertyData;
    const formData: FormData = new FormData();
    formData.append('propertyId', propertyId);

    for (const file of Array.from(fileList)) {
      formData.append('images', file);

      const propertyImage: PropertyImage = {
        fileName: file.name,
        isInProgress: true,
        selected: true,
      };

      const fileReader = new FileReader();
      fileReader.onload = () => (propertyImage.url = fileReader.result as string);
      fileReader.readAsDataURL(file);

      this.selectedSourceData.transform(activePropertyData?.imageWithLabels).unshift(propertyImage);
    }
    this.propertyState.activePropertyData = activePropertyData;

    return this.documentPreviewApi.uploadFiles(formData).pipe(
      map(() => {
        if (this.selectedSourceData.transform(activePropertyData?.imageWithLabels)) {
          const transformedData = this.selectedSourceData
            .transform(activePropertyData?.imageWithLabels)
            .map((image: any) => {
              image.isInProgress = false;
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return image;
            });

          if (this.propertyState.activePropertyData) {
            this.propertyState.activePropertyData = {
              ...activePropertyData,
              imageWithLabels: transformedData,
            };
          }
        }
        return true;
      }),
      catchError(() => {
        if (this.selectedSourceData.transform(activePropertyData?.imageWithLabels)) {
          const filteredData = this.selectedSourceData
            .transform(activePropertyData?.imageWithLabels)
            .filter((image: any) => !image.isInProgress);
          this.propertyState.activePropertyData = {
            ...activePropertyData,
            imageWithLabels: filteredData,
          };
        }
        this.snackBarService.open('error', 'Images could not be uploaded. Please try again later.');
        return of(true);
      })
    );
  }

  /**
   * Select or deselect an image from the list of uploaded images.
   * Only these selected images will be visible in the final report.
   * @param imageUrl
   * @returns true if success and false if failure
   */
  toggleActivePropertyImages(): Observable<boolean> {
    return new Observable();
  }

  parsePropertyRoomDetails(data: any): any {
    const resultObj: any = {};
    const objTemplate = {
      area: '',
      areaunits: '',
      areasource: '',
      dimensions: '',
      length: '',
      width: '',
      lengthwidthunits: '',
      lengthwidthsource: '',
      level: '',
      features: '',
      description: '',
    };
    Object.keys(data).forEach((key: string) => {
      const curObj = this._makeKey(key);
      curObj.uniqueKey = curObj && curObj.uniqueKey ? this._capitalize(curObj.uniqueKey) : '';
      if (curObj) {
        if (!resultObj[curObj.uniqueKey]) {
          resultObj[curObj.uniqueKey] = structuredClone(objTemplate);
        }
        let curData = data[key];
        if (this._isObject(data[key])) {
          curData = JSON.stringify(data[key]).replace(/[{}]/g, '');
        } else {
          curData = data[key]?.trim();
        }
        if (resultObj[curObj.uniqueKey][curObj.curKey]) {
          resultObj[curObj.uniqueKey][curObj.curKey] = resultObj[curObj.uniqueKey][curObj.curKey] + ' ' + curData;
        } else {
          resultObj[curObj.uniqueKey][curObj.curKey] = curData;
        }
      }
    });
    if (resultObj) {
      return Object.keys(resultObj)
        .map((roomName) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return { roomName, ...resultObj[roomName] };
        })
        .sort((a, b) => (a.roomName.toLowerCase() < b.roomName.toLowerCase() ? -1 : 1));
    } else {
      return [];
    }
  }

  /**
   * Fetch property based on the propertyId
   * @param propertyId
   * @returns PropertyDetail
   */
  getPropertyById(propertyId: string): ExtendedPropertyDetail | null {
    if (propertyId == this.propertyState.subjectPropertyData?.id) return this.propertyState.subjectPropertyData;

    if (propertyId == this.propertyState.activePropertyData?.id) return this.propertyState.activePropertyData;

    if (this.salesApproachState.selectedComparableMap[propertyId])
      return this.salesApproachState.selectedComparableMap[propertyId];

    if (this.costApproachState.selectedComparableMap[propertyId])
      return this.costApproachState.selectedComparableMap[propertyId];

    if (this.shortTermRentalState.selectedComparableMap[propertyId])
      return this.shortTermRentalState.selectedComparableMap[propertyId];

    return null;
  }

  downloadPropertyCSV(data: PropertyDetail, filename: string): void {
    const csvData = this._convertJsonToCSV(data);

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    this.snackBarService.open('success', 'Property CSV downloaded successfully.');
  }

  downloadPropertyPDF(
    mlsListingNumber?: string,
    mlsBoard?: string,
    street?: string,
    zip?: string
  ): Observable<boolean> {
    const requestBody: GenerateListingSheetApiRequest = {
      OrderId: 'PROFET_VAL_PROPERTY_' + new Date().getTime(),
      ...(mlsListingNumber ? { MLSListingNumber: mlsListingNumber } : {}),
      ...(mlsBoard ? { MLSBoard: mlsBoard } : {}),
      ...(street ? { Street: street } : {}),
      ...(zip ? { Zip: zip } : {}),
    };

    return this.proxyService.initPropertyPdfGeneration(requestBody).pipe(
      switchMap((referenceId) => {
        if (!referenceId) return of(false);

        return this.proxyService.fetchGeneratedPropertyPdf(referenceId).pipe(
          repeat({ count: 5, delay: 3000 }),
          takeWhile((pdfString) => !pdfString, true),
          map((pdfString) => {
            if (pdfString) {
              const address =
                this.selectedSourceDataPipe.transform(this.propertyState.activePropertyData?.propertyStreetAddress) +
                '_' +
                this.selectedSourceDataPipe.transform(this.propertyState.activePropertyData?.propertyCity) +
                '_' +
                this.selectedSourceDataPipe.transform(this.propertyState.activePropertyData?.propertyState) +
                '_' +
                this.selectedSourceDataPipe.transform(this.propertyState.activePropertyData?.propertyZip);
              this._downloadPDF(pdfString, address);
              this.snackBarService.open('success', 'Property PDF downloaded successfully.');
            }

            return false;
          }),
          endWith(false)
        );
      }),
      startWith(true),
      catchError(({ error }: HttpErrorResponse) => {
        this.snackBarService.open('error', (error?.message as string) || 'Unable to download Property PDF.');
        return of(false);
      })
    );
  }

  public getPropertyDataSourceType() {
    return ['userRecord', 'inspectionRecord', 'listingRecord', 'publicRecord'];
  }

  private _convertJsonToCSV(data: PropertyDetail): string {
    const rows: string[] = [];
    const headers = Object.keys(data).join(',');
    rows.push(headers);

    const values = Object.values(data)
      .map((value) => {
        if (value && typeof value === 'object' && 'selectedValue' in value) {
          let val = value.selectedValue ?? '';
          if (typeof val === 'string' && val.includes(',')) {
            val = `"${val.replace(/"/g, '""')}"`;
          }
          return val;
        }
        return '';
      })
      .join(',');

    rows.push(values);
    return rows.join('\n');
  }

  private _downloadPDF(pdfData: string, fileName: string): void {
    const byteNumbers = new Array(pdfData.length);
    for (let i = 0; i < pdfData.length; i++) {
      byteNumbers[i] = pdfData.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const anchorElement = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    anchorElement.href = url;
    anchorElement.download = fileName;
    anchorElement.click();
    window.URL.revokeObjectURL(url);
  }

  private _fetchPropertyData(
    reportType: ValuationOrderSubType,
    propertyId: string,
    listingId?: string,
    pmxProperty?: boolean,
    responseKeys?: PropertyAttribute[]
  ): Observable<ExtendedPropertyDetail> {
    const requestBody: PropertyDataApiRequest = {
      pageName: 'propertyDetails',
      reportType,
      ...(listingId ? { listingId } : {}),
      ...(pmxProperty ? { pmxPropertyId: propertyId } : { propertyId: propertyId }),
      ...(responseKeys ? { responseKeys } : {}),
    };
    return this.propertyApi.getActivePropertyPageData(requestBody).pipe(
      map((data) => {
        const formattedData: ExtendedPropertyDetail = this.commonService.formatPropertyData(data);
        return formattedData;
      })
    );
  }

  private _isObject(value: any): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value && typeof value === 'object' && value.constructor === Object;
  }

  private _makeKey(key: string): any {
    const roomAttributes: { [key: string]: string[] } = {
      area: ['area', 'areas'],
      areaunits: ['areaunits', 'areaunit'],
      areasource: ['areasource', 'areasources'],
      dimensions: ['dimensions', 'dimension', 'dmnsn'],
      length: ['length', 'lengths', 'lngth'],
      width: ['width', 'widths'],
      lengthwidthunits: ['lengthwidthunits', 'lengthwidthunit', 'lwu'],
      lengthwidthsource: ['lengthwidthsource', 'lengthwidthsources', 'lws'],
      level: ['level', 'lvl', 'lev'],
      features: ['features', 'feature'],
      description: ['description', 'descriptions', 'desc'],
    };
    let res: any = false;
    let tmpKey = key.toString().toLowerCase();
    if (tmpKey.startsWith('room')) {
      tmpKey = tmpKey.replace('room', '');
    }
    const postFixData = Object.keys(roomAttributes);

    for (let x = 0; x < postFixData.length; x++) {
      for (let y = 0; y < roomAttributes[postFixData[x]].length; y++) {
        const labelSearchElement = roomAttributes[postFixData[x]][y];
        tmpKey = this._isLastIndexANum(tmpKey);
        if (tmpKey.endsWith(labelSearchElement)) {
          tmpKey = tmpKey.substring(0, tmpKey.lastIndexOf(labelSearchElement));
          res = { curKey: postFixData[x], uniqueKey: tmpKey };
          break;
        }
      }
    }

    if (!res) {
      res = { curKey: 'description', uniqueKey: tmpKey };
    }
    return res;
  }

  private _isLastIndexANum(str: string): string {
    if (!isNaN(Number(str[str.length - 1]))) {
      for (let i = str.length - 1; i >= 0; i--) {
        if (this._isStringNum(str[i])) {
          str = str.substring(0, str.length - 1);
        }
      }
      return str;
    } else {
      return str;
    }
  }

  private _isStringNum(string: string): boolean {
    if (!string) return false;
    return !isNaN(Number(string));
  }

  private _capitalize(roomType: string): string {
    if (typeof roomType !== 'string') return '';
    return roomType.charAt(0).toUpperCase() + roomType.slice(1);
  }
}
