import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AsyncDataState } from 'src/app/module/async/state/async-data.state';
import { DeedHistory, HistoryType, ListingHistory } from 'src/app/module/property/interface/property.interface';
import { PropertyState } from 'src/app/module/property/state/property.state';
import { SelectedSourceDataPipe } from '../../pipes/selected-source-data.pipe';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class HistoryListComponent implements OnChanges {
  columnsToDisplay: string[] = [];
  columnsToDisplayWithExpand: string[] = [];
  expandedColumnsToDisplay: string[] = [];
  expandedElement!: ListingHistory | DeedHistory;
  dataSource: any = [];
  excludedKeys: string[] = [
    'isActive',
    'children',
    'Buyer2FullName',
    'Seller2FullName',
    'ListingId',
    'MlsStatus',
    'saleDate',
  ];
  expandedExcludedKeys = [
    'isActive',
    'children',
    'Buyer2FullName',
    'Seller2FullName',
    'Buyer1FullName',
    'Seller1FullName',
    'TransactionRecordingDate',
    'TransactionContractDate',
    'TransactionDocumentType',
    'TransactionRecordingDocumentId',
    'ListingId',
    'MlsStatus',
    'saleDate',
  ];
  onDestroyNotifier$ = new Subject<void>();
  isAsyncLoading: boolean = false;

  @Input() historyList!: ListingHistory[] | DeedHistory[];
  @Input() historyType!: HistoryType;
  @Input() isEditable: boolean = true;
  @Output() onChangePriorSale = new EventEmitter<{
    history: ListingHistory[] | DeedHistory[];
    historyType: HistoryType;
  }>();
  @Input() propertyId!: string;

  constructor(
    private propertyState: PropertyState,
    private selectedSourceDataPipe: SelectedSourceDataPipe,
    private asyncDataState: AsyncDataState
  ) {}

  ngOnInit() {
    this.asyncDataState.asyncPendingData$.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((data) => {
      if (data?.length) {
        const property = data.find((p) => p.id == this.propertyId);
        this.isAsyncLoading = property ? true : false;
      } else this.isAsyncLoading = false;
    });
  }

  ngOnChanges() {
    this.dataSource = this.historyList ?? [];
    if (!this.isEditable) {
      this.excludedKeys.push('isPriorSale');
      this.expandedExcludedKeys.push('isPriorSale');
    }

    this.columnsToDisplay = this.dataSource[0]
      ? Object.keys(this.dataSource[0])?.filter((key) => !this.excludedKeys.includes(key))
      : [];
    this.columnsToDisplayWithExpand =
      this.historyType != HistoryType.deed ? [...this.columnsToDisplay, 'expand'] : [...this.columnsToDisplay];

    this.expandedColumnsToDisplay = this.dataSource[0]
      ? Object.keys(this.dataSource[0])?.filter((key) => !this.expandedExcludedKeys.includes(key))
      : [];
    this.expandedColumnsToDisplay = [...this.expandedColumnsToDisplay, 'expand'];
  }

  onPriorSaleChange(element: ListingHistory | DeedHistory, index: number) {
    element.isPriorSale = !element.isPriorSale;

    //updating already selected prior sale if any
    const activePriorSaleIndex =
      this.historyType == HistoryType.deed
        ? (this.dataSource as DeedHistory[])?.findIndex((item, i) => index != i && item.isPriorSale)
        : (this.dataSource as ListingHistory[])?.findIndex((item, i) => index != i && item.isPriorSale);
    if (activePriorSaleIndex > -1) this.dataSource[activePriorSaleIndex].isPriorSale = false;
    this.onChangePriorSale.emit({ history: this.dataSource, historyType: this.historyType });
  }

  onView(listingId: string | null) {
    this.propertyState.activePropertyHistoryListingId = null;
    const activeListingId = this.selectedSourceDataPipe.transform(this.propertyState.activePropertyData?.ListingId);
    setTimeout(() => {
      if (listingId && activeListingId != listingId) this.propertyState.activePropertyHistoryListingId = listingId; //this.openHistory.emit(listingId);
    }, 500);
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}
