<div class="no-content-image">
  <img src="/assets/icon/no-content.svg" width="150" />
</div>
<div class="no-content-reason">
  <div class="description">
    <h1>{{ bannerTitle }}</h1>
    <h2 *ngIf="bannerSubTitle">{{ bannerSubTitle }}</h2>
  </div>
  <div class="action">
    <ng-content></ng-content>
  </div>
</div>
