import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { DuplicateOrderList } from 'src/app/module/order/interface/order.interface';
import { ProgressLoaderService } from 'src/app/shared/services/progress-loader.service';
import { ProxyService } from 'src/app/shared/services/proxy.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { NewOrderApi } from '../api/new-order.api';
import {
  DuplicateOrderParam,
  NewOrder,
  PropertySearchCriteria,
  SearchMode,
  SearchedPropertyData,
} from '../interface/new-order.interface';
import { NewOrderState } from '../state/new-order.state';

@Injectable({
  providedIn: 'root',
})
export class NewOrderService {
  constructor(
    private newOrderApi: NewOrderApi,
    private _router: Router,
    private snackBarService: SnackBarService,
    private proxyService: ProxyService,
    private progressLoaderService: ProgressLoaderService,
    private newOrderState: NewOrderState
  ) {}

  searchProperty(searchCriteria: PropertySearchCriteria, mode: SearchMode): Observable<SearchedPropertyData> {
    return this.proxyService.searchProperty(searchCriteria, mode);
  }

  searchDuplicateOrders(searchCriteria: DuplicateOrderParam): Observable<DuplicateOrderList[]> {
    return this.newOrderApi.searchDuplicateOrders(searchCriteria).pipe(catchError(() => of([])));
  }

  createOrder(newOrder: NewOrder | null): Observable<boolean> {
    this.progressLoaderService.show().message('Creating Order');
    return this.newOrderApi.createOrder({ ...newOrder }).pipe(
      map((orderId) => {
        this.progressLoaderService.hide();
        this.newOrderState.newOrder = null;
        this.newOrderState.searchPropertyQuery = null;
        this._router.navigate([`orders/${orderId ?? ''}`]);
        return false;
      }),
      catchError((error) => {
        this.progressLoaderService.hide();
        this.snackBarService.open('error', error.error.message);
        return of(false);
      })
    );
  }
}
