import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { allowSpecialCharsValidator } from 'src/app/shared/validator/special-chara.validator';

@Component({
  selector: 'profet-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultValueAccessor,
      multi: true,
    },
  ],
})
export class InputComponent implements OnInit, AfterViewInit, OnDestroy {
  formCtrl!: FormControl;
  _allowedSpecialChars: string[] | undefined;

  // Subscription to unsubscribe all observables on component destroy
  onDestroyNotifier$ = new Subject<void>();

  @Input() label: string | null = null;
  @Input() type: 'text' | 'number' | 'numberString' | 'tel' | 'email' | 'password' = 'text';
  @Input() prefixIcon: string | undefined;
  @Input() prefixText: string | undefined;
  @Input() suffixIcon: string | undefined;
  @Input() suffixText: string | undefined;
  @Input() infoText: string | undefined;
  @Input() hint: string | undefined;
  @Input() hideHint: boolean = false;
  @Input() hidden: boolean = false;
  @Input() required: boolean = false;
  @Input() isPercentage: boolean = false;
  @Input() allowNegativeNumbers: boolean = true;

  @Input() set allowedSpecialChars(value: string[] | undefined) {
    this._allowedSpecialChars = value;

    if (value === null || value?.length === 0) this.hint = 'No special characters allowed';
    const formattedChars = value?.map((char) => `'${char}'`).join(', ');

    if (value?.length) {
      this.hint = `Allowed special characters: ${formattedChars}`;
    }
  }

  @Output() suffixBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.hidden') get hideClass() {
    return this.hidden;
  }

  @HostBinding('class.hint-hidden') get hintHideClass() {
    return this.hideHint;
  }

  constructor(public ngControl: NgControl) {}

  ngOnInit(): void {
    this.formCtrl = new FormControl(this.modelCtrlValue, {
      validators: allowSpecialCharsValidator(this._allowedSpecialChars),
    });
  }

  ngAfterViewInit(): void {
    // Disable the control if it is disabled in the parent form
    if (this.ngControl.status === 'DISABLED') this.formCtrl.disable();

    this.ngControl.statusChanges
      ?.pipe(takeUntil(this.onDestroyNotifier$), distinctUntilChanged())
      .subscribe((status) => {
        if (status === 'DISABLED') this.formCtrl.disable();
        else this.formCtrl.enable();
      });

    this.ngControl.control?.valueChanges
      .pipe(takeUntil(this.onDestroyNotifier$), distinctUntilChanged())
      .subscribe((value) => this.formCtrl.setValue(value, { emitEvent: false }));

    this.formCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$), distinctUntilChanged()).subscribe((value) => {
      this.modelCtrlValue = value;

      if (this.formCtrl.errors) {
        this.ngControl.control?.setErrors({ ...this.formCtrl.errors });
      } else {
        this.ngControl.control?.setErrors(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  onSuffixBtnClick() {
    this.suffixBtnClicked.emit(true);
  }

  get modelCtrl(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get modelCtrlValue(): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (this.ngControl.control as FormControl)?.value;
  }

  set modelCtrlValue(value: any) {
    if (this.ngControl.control) {
      // If Number type, Explicitly cast the value to number
      if (this.type == 'number') {
        value = [null, undefined, ''].includes(value) ? null : Number(value);
        if (isNaN(value)) {
          value = null;
        }
        this.ngControl.control.setValue(value);
      } else {
        this.ngControl.control.setValue(value);
      }
    }
  }
}
