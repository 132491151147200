import { GroupIndicatorChart, SingleIndicatorChart } from 'src/app/shared/interface/company.interface';
import { SelectOptionData } from 'src/app/shared/interface/profet-control-widget.interface';
import { OrderType, ValuationOrderSubType } from 'src/app/shared/interface/report.interface';
import {
  InsightKeyIndicatorConfig,
  NeighborhoodInsight,
  NeighborhoodInsightChartConfig,
} from '../interface/neighborhood.interface';
import {
  ApproachKeys,
  ApproachTypes,
  CompSearchSubFilter,
  CompSearchType,
  NotificationStatus,
  OrderRoutePathConfig,
  PrimarySearchFilter,
  PropertyType,
  StatusType,
  TimeFrameType,
  ValuationType,
} from '../interface/order.interface';

export class OrderConfig {
  static readonly valuationOrderType: SelectOptionData[] = [
    {
      key: ValuationOrderSubType.desktop,
      displayValue: 'Desktop',
    },
    {
      key: ValuationOrderSubType.appraisal,
      displayValue: 'Appraisal',
    },
    {
      key: ValuationOrderSubType.evaluation,
      displayValue: 'Evaluation',
    },
    {
      key: ValuationOrderSubType.avm,
      displayValue: 'AVM',
    },
    {
      key: ValuationOrderSubType.bpo,
      displayValue: 'BPO',
    },
  ];

  static readonly orderType: SelectOptionData[] = [
    {
      key: OrderType.valuation,
      displayValue: 'Valuation',
      children: OrderConfig.valuationOrderType,
    },
    {
      key: OrderType.complexity,
      displayValue: 'Complexity',
    },
    {
      key: OrderType.property,
      displayValue: 'Property',
    },
    {
      key: OrderType.shortTermRental,
      displayValue: 'Short Term Rental',
    },
  ];

  /**
   * Default values to be used for searching comparables while creating a new order.
   */
  static readonly defaultPrimarySearchFilter: PrimarySearchFilter = {
    bathsRange: {
      from: null,
      to: null,
      tolerance: null,
      source: null,
    },
    bedsRange: {
      from: null,
      to: null,
      tolerance: null,
      source: null,
    },
    effectiveDate: null,
    includePublicRecordSales: true,
    limitToSubjectZip: true,
    listDateRange: {
      from: null,
      to: null,
    },
    livingAreaRange: {
      from: null,
      to: null,
      tolerance: null,
      source: null,
    },
    lotSizeRange: {
      from: null,
      to: null,
      tolerance: null,
      source: null,
    },
    monthsBack: 12,
    offMarketStatusList: null,
    polygonList: null,
    priceRange: {
      from: null,
      to: null,
    },
    propertyTypeList: null,
    radius: 2,
    saleType: null,
    schoolDistrict: null,
    searchMode: CompSearchType.radius,
    soldDateRange: {
      from: null,
      to: null,
    },
    statusList: [StatusType.active, StatusType.sold, StatusType.pending],
    storiesRange: {
      from: null,
      to: null,
      tolerance: null,
      source: null,
    },
    timeframeOption: TimeFrameType.effectiveDate,
    valuationType: ValuationType.sales,
    yearBuiltRange: {
      from: null,
      to: null,
      tolerance: null,
      source: null,
    },
  };

  static readonly defaultCompSearchSubFilter: CompSearchSubFilter = {
    includeHOA: null,
    includeSolarPower: null,
    includeGuestHouse: null,
    includeFireplace: null,
    includeGarage: null,
    subDivisionName: '',
    viewFactor: null,
    garageType: null,
    poolType: null,
    basementType: null,
    basementFinishedAreaRange: { from: 0, to: 0 },
    basementFinishedTotalAreaRange: { from: 0, to: 0 },
    address: '',
    city: '',
    state: '',
    zipCode: '',
    propertyType: PropertyType.singleFamily,
    status: StatusType.active,
    bedsRange: { from: 0, to: 0 },
    bathsRange: { from: 0, to: 0 },
    livingAreaRange: { from: 0, to: 0 },
    listPriceRange: { from: 0, to: 0 },
    soldPriceRange: { from: 0, to: 0 },
    storiesRange: { from: 0, to: 0 },
    similarityScoreRange: { from: 0, to: 0 },
  };

  static readonly viewOptionList: SelectOptionData[] = [
    {
      key: null,
      displayValue: 'Any View',
    },
    {
      key: 'waterFront',
      displayValue: 'Water Front',
    },
    {
      key: 'woods',
      displayValue: 'Woods',
    },
    {
      key: 'park',
      displayValue: 'Park',
    },
    {
      key: 'pastoral',
      displayValue: 'Pastoral',
    },
    {
      key: 'golfCourse',
      displayValue: 'Golf Course',
    },
    {
      key: 'citySkyline',
      displayValue: 'City Sky Line',
    },
    {
      key: 'mountain',
      displayValue: 'Mountain',
    },
    {
      key: 'residential',
      displayValue: 'Residential',
    },
    {
      key: 'cityStreet',
      displayValue: 'City Street',
    },
    {
      key: 'industrial',
      displayValue: 'Industrial',
    },
    {
      key: 'powerLines',
      displayValue: 'Power Lines',
    },
    {
      key: 'limitedSight',
      displayValue: 'Limited Sight',
    },
  ];

  static readonly garageOptionList: SelectOptionData[] = [
    {
      key: null,
      displayValue: 'Any Garage',
    },
    {
      key: 'attached',
      displayValue: 'Attached',
    },
    {
      key: 'builtIn',
      displayValue: 'Built-In',
    },
    {
      key: 'carPort',
      displayValue: 'Car Port',
    },
    {
      key: 'detached',
      displayValue: 'Detached',
    },
    {
      key: 'unknown',
      displayValue: 'Unknown',
    },
  ];

  static readonly poolOptionList: SelectOptionData[] = [
    {
      key: null,
      displayValue: 'Any Pool',
    },
    {
      key: 'inGround',
      displayValue: 'In Ground',
    },
    {
      key: 'aboveGround',
      displayValue: 'Above Ground',
    },
    {
      key: 'unknown',
      displayValue: 'Unknown',
    },
  ];

  static readonly basementOptionList: SelectOptionData[] = [
    {
      key: null,
      displayValue: 'Any Basement',
    },
    {
      key: 'crawlSpace',
      displayValue: 'Crawl Space',
    },
    {
      key: 'fullBasement',
      displayValue: 'Full Basement',
    },
    {
      key: 'partialBasement',
      displayValue: 'Partial Basement',
    },
    {
      key: 'unknown',
      displayValue: 'Unknown',
    },
  ];

  /**
   * Default value set to NeighborhoodInsights state.
   */
  static readonly defaultNeighborhoodInsight: NeighborhoodInsight = {
    zipCodeInsight: {
      data: [],
      selectedPropertyType: [],
      selectedChart: [],
    },
    comparableInsight: {
      data: [],
      selectedPropertyType: [],
      selectedChart: [],
    },
  };

  /**
   * Neighborhood insight chart configuration used by Zip-Code Insights charts and Comparable Insights charts.
   */
  static readonly neighborhoodInsightChartConfig: NeighborhoodInsightChartConfig[] = [
    {
      key: GroupIndicatorChart.absorptionRate,
      displayValue: 'Absorption Rate',
      propertyType: 'subject',
      dataType: 'number',
      dataset: [
        {
          chartType: 'line',
          dataKey: 'MonthsOfSupply',
          label: 'Months of Supply',
        },
        {
          chartType: 'bar',
          dataKey: 'ForSaleCount',
          label: 'Count of Listings',
        },
        {
          chartType: 'bar',
          dataKey: 'SoldCount',
          label: 'Count of Contracts',
        },
      ],
    },
    {
      key: GroupIndicatorChart.medianDOM,
      displayValue: 'Median Days on Market',
      propertyType: 'subject',
      dataType: 'number',
      dataset: [
        {
          chartType: 'line',
          dataKey: 'AvgDOM',
          label: 'Median Days on Market',
        },
      ],
    },
    {
      key: GroupIndicatorChart.priceTrend,
      displayValue: 'Price Trends',
      propertyType: 'subject',
      dataType: 'currency',
      dataset: [
        {
          chartType: 'line',
          dataKey: 'MedianSoldPricePerSqft',
          label: 'Median SP/sq.ft.',
        },
        {
          chartType: 'bar',
          dataKey: 'MedianListPrice',
          label: 'Median List Price',
        },
        {
          chartType: 'bar',
          dataKey: 'MedianSoldPrice',
          label: 'Median Sale Price',
        },
      ],
    },
    {
      key: GroupIndicatorChart.medianSoldToListPrice,
      displayValue: 'Median Sold-Price-to-List-Price %',
      propertyType: 'subject',
      dataType: 'number',
      dataset: [
        {
          chartType: 'bar',
          dataKey: 'MedianSoldToListPricePercent',
          label: 'Median Sold-Price-to-List-Price %',
        },
      ],
    },
    {
      key: SingleIndicatorChart.medianListPrice,
      displayValue: 'Median List Price',
      propertyType: 'all',
      dataType: 'currency',
      dataset: [
        {
          chartType: 'line',
          dataKey: 'MedianListPrice',
          label: 'Median List Price',
        },
      ],
    },
    {
      key: SingleIndicatorChart.avgDOM,
      displayValue: 'Average Days on Market',
      propertyType: 'all',
      dataType: 'number',
      dataset: [
        {
          chartType: 'line',
          dataKey: 'AvgDOM',
          label: 'Average Days on Market',
        },
      ],
    },
    {
      key: SingleIndicatorChart.medianSoldPricePerSqft,
      displayValue: 'Median Sold Price per Sqft',
      propertyType: 'all',
      dataType: 'currency',
      dataset: [
        {
          chartType: 'line',
          dataKey: 'MedianSoldPricePerSqft',
          label: 'Median Sold Price per Sqft',
        },
      ],
    },
    {
      key: SingleIndicatorChart.inventory,
      displayValue: 'Inventory',
      propertyType: 'all',
      dataType: 'number',
      dataset: [
        {
          chartType: 'line',
          dataKey: 'NewListingCount',
          label: 'Inventory',
        },
      ],
    },
  ];

  static readonly chartPropertyTypeConfig = {
    all: {
      dataKey: 'Residential',
      displayValue: 'All',
    },
    singleFamilyHomes: {
      dataKey: 'SingleFamily',
      displayValue: 'Single Family',
    },
    condos: {
      dataKey: 'Condominium',
      displayValue: 'Condominium',
    },
    townHomes: {
      dataKey: 'TownHouse',
      displayValue: 'TownHouse',
    },
  };

  static readonly neighborhoodInsightKeyIndicatorConfig: InsightKeyIndicatorConfig[] = [
    {
      displayValue: 'Median List Price',
      dataKey: 'MedianListPrice',
      dataType: 'currency',
    },
    {
      displayValue: 'Asking Price/sq ft',
      dataKey: 'MedianListPricePerSqft',
      dataType: 'currency',
    },
    {
      displayValue: 'Average Days on Market',
      dataKey: 'AvgDOM',
      dataType: 'number',
    },
    {
      displayValue: 'New Listings',
      dataKey: 'NewListingCount',
      dataType: 'number',
    },
    {
      displayValue: 'Total Sold',
      dataKey: 'SoldCount',
      dataType: 'number',
    },
    {
      displayValue: 'Median House Size(sq ft)',
      dataKey: 'MedianGLA',
      dataType: 'number',
    },
    {
      displayValue: 'Median Bedrooms Count',
      dataKey: 'MedianBed',
      dataType: 'number',
    },
    {
      displayValue: 'Median Bathrooms Count',
      dataKey: 'MedianBath',
      dataType: 'number',
    },
  ];

  static defaultOrderRoutePathList: OrderRoutePathConfig[] = [
    {
      route: 'basic-info',
      displayValue: 'Order Summary',
      jsonPath: '',
      companySettingJsonPath: null,
      children: [],
      validationGroup: [],
      validationLabel: 'Order Summary',
      validationRoute: 'basic-info',
      orderType: ['desktopAppraisalReport', 'bpoReport', 'shortTermRentalReport'],
    },
    {
      route: 'subject',
      displayValue: 'Subject Property',
      jsonPath: 'subject',
      companySettingJsonPath: null,
      children: [],
      validationGroup: [],
      validationLabel: 'Subject Property',
      validationRoute: 'subject',
      orderType: ['desktopAppraisalReport', 'bpoReport', 'shortTermRentalReport'],
    },
    {
      route: 'sales-approach',
      displayValue: 'Sales Comparison',
      jsonPath: 'salesComparison',
      companySettingJsonPath: null,
      validationGroup: [],
      validationLabel: 'Sales Comparison',
      validationRoute: 'sales-approach',
      orderType: ['desktopAppraisalReport', 'bpoReport'],
      children: [
        {
          route: 'selection',
          displayValue: 'Comps',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Sales Comparison > Comps',
          validationRoute: 'sales-approach/selection',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
        },
        {
          route: 'adjustment',
          displayValue: 'Grid',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: ['subject', 'sales-approach/adjustment'],
          validationLabel: 'Sales Comparison > Grid',
          validationRoute: 'sales-approach/adjustment',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
          valuationType: ValuationType.sales,
        },
        {
          route: 'priorSale',
          displayValue: 'Prior Sales',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Sales Comparison > Prior Sales',
          validationRoute: 'sales-approach/priorSale',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
        },
      ],
    },
    {
      route: 'cost-approach',
      displayValue: 'Cost Approach',
      jsonPath: 'costApproach',
      companySettingJsonPath: 'costApproach.enabled',
      validationGroup: [],
      validationLabel: 'Cost Approach',
      validationRoute: 'cost-approach',
      children: [
        {
          route: 'building',
          displayValue: 'Bldg',
          jsonPath: 'costOfBuildingDetail',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Cost Approach > Bldg',
          validationRoute: 'cost-approach/building',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
        },
        {
          route: 'land',
          displayValue: 'Land',
          jsonPath: 'costOfLandSaleDetail',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Cost Approach > Land',
          validationRoute: 'cost-approach/land',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
        },
      ],
      orderType: ['desktopAppraisalReport', 'bpoReport'],
    },
    {
      route: 'short-term-rental',
      displayValue: 'Short Term Rent',
      jsonPath: 'shortTermRentalReport',
      companySettingJsonPath: 'enabled',
      validationGroup: [],
      validationLabel: 'Short Term Rent',
      validationRoute: 'short-term-rental',
      children: [
        {
          route: 'selection',
          displayValue: 'Comps',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Short Term Rent > Comps',
          validationRoute: 'short-term-rental/selection',
          children: [],
          orderType: ['shortTermRentalReport'],
        },
        {
          route: 'adjustment',
          displayValue: 'Grid',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: ['subject', 'short-term-rental/adjustment'],
          validationLabel: 'Short Term Rent > Grid',
          validationRoute: 'short-term-rental/adjustment',
          children: [],
          orderType: ['shortTermRentalReport'],
          valuationType: ValuationType.shortTermRental,
        },
        {
          route: 'income',
          displayValue: 'Income',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Short Term Rent > Income',
          validationRoute: 'short-term-rental/income',
          children: [],
          orderType: ['shortTermRentalReport'],
        },
      ],
      orderType: ['shortTermRentalReport'],
    },
    {
      route: 'income-approach',
      displayValue: 'Income Approach',
      jsonPath: 'incomeApproach',
      companySettingJsonPath: null,
      validationGroup: [],
      validationLabel: 'Income Approach',
      validationRoute: 'income-approach',
      children: [
        {
          route: 'short-term-rental',
          displayValue: 'Short Term Rent',
          jsonPath: 'incomeApproach.shortTermRental',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Income Approach > Short Term Rent',
          validationRoute: 'income-approach/short-term-rental',
          children: [
            {
              route: 'selection',
              displayValue: 'ST Comps',
              jsonPath: '',
              companySettingJsonPath: null,
              validationGroup: [],
              validationLabel: 'Income Approach > Short Term Rent > Comps',
              validationRoute: 'income-approach/short-term-rental/selection',
              children: [],
              orderType: [],
            },
            {
              route: 'adjustment',
              displayValue: 'ST Grid',
              jsonPath: '',
              companySettingJsonPath: null,
              validationGroup: [],
              validationLabel: 'Income Approach > Short Term Rent > Grid',
              validationRoute: 'income-approach/short-term-rental/adjustment',
              children: [],
              orderType: [],
              valuationType: ValuationType.shortTermRental,
            },
            {
              route: 'income',
              displayValue: 'ST Income',
              jsonPath: '',
              companySettingJsonPath: null,
              validationGroup: [],
              validationLabel: 'Income Approach > Short Term Rent > Income',
              validationRoute: 'income-approach/short-term-rental/income',
              children: [],
              orderType: [],
            },
          ],
          orderType: [],
        },
        {
          route: 'long-term-rental',
          displayValue: 'Long Term Rent',
          jsonPath: 'incomeApproach.longTermRental',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Income Approach > Long Term Rent',
          validationRoute: 'income-approach/long-term-rental',
          children: [
            {
              route: 'selection',
              displayValue: 'LT Comps',
              jsonPath: '',
              companySettingJsonPath: null,
              validationGroup: [],
              validationLabel: 'Income Approach > Long Term Rent > Comps',
              validationRoute: 'income-approach/long-term-rental/selection',
              children: [],
              orderType: [],
            },
            {
              route: 'adjustment',
              displayValue: 'LT Grid',
              jsonPath: '',
              companySettingJsonPath: null,
              validationGroup: [],
              validationLabel: 'Income Approach > Long Term Rent > Grid',
              validationRoute: 'income-approach/long-term-rental/adjustment',
              children: [],
              orderType: [],
              valuationType: ValuationType.longTermRental,
            },
            {
              route: 'income',
              displayValue: 'LT Income',
              jsonPath: '',
              companySettingJsonPath: null,
              validationGroup: [],
              validationLabel: 'Income Approach > Long Term Rent > Income',
              validationRoute: 'income-approach/long-term-rental/income',
              children: [],
              orderType: [],
            },
          ],
          orderType: [],
        },
        {
          route: 'value',
          displayValue: 'Value',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Income Approach > Long Term Rent > Value',
          validationRoute: 'income-approach/long-term-rental/value',
          children: [],
          orderType: [],
        },
      ],
      orderType: [],
    },
    {
      route: 'neighborhood',
      displayValue: 'Neighborhood',
      jsonPath: 'neighborhood',
      companySettingJsonPath: null,
      validationGroup: [],
      validationLabel: 'Neighborhood',
      validationRoute: 'neighborhood',
      orderType: ['desktopAppraisalReport', 'bpoReport'],
      children: [
        {
          route: 'locality',
          displayValue: 'Locality',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Neighborhood > Inventory',
          validationRoute: 'neighborhood/locality',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
        },
        {
          route: 'inventory',
          displayValue: 'Inventory',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Neighborhood > Inventory',
          validationRoute: 'neighborhood/inventory',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
        },
        {
          route: 'trends',
          displayValue: 'Trends',
          jsonPath: '',
          companySettingJsonPath: null,
          validationGroup: [],
          validationLabel: 'Neighborhood > Trends',
          validationRoute: 'neighborhood/trends',
          children: [],
          orderType: ['desktopAppraisalReport', 'bpoReport'],
        },
      ],
    },
    {
      route: 'project-info',
      displayValue: 'Project Information',
      jsonPath: 'projectInformation',
      companySettingJsonPath: 'projectInformation',
      children: [],
      validationGroup: [],
      validationLabel: 'Project Information',
      validationRoute: 'project-info',
      orderType: ['desktopAppraisalReport', 'bpoReport'],
    },
    {
      route: 'value-reconciliation',
      displayValue: 'Value Reconciliation',
      jsonPath: 'valueReconciliation',
      companySettingJsonPath: 'reconciliation.enabled',
      children: [],
      validationGroup: [],
      validationLabel: 'Value Reconciliation',
      validationRoute: 'value-reconciliation',
      orderType: ['desktopAppraisalReport', 'bpoReport'],
    },
    {
      route: 'additionalDocs',
      displayValue: 'Docs and Comments',
      jsonPath: 'additionalFiles',
      companySettingJsonPath: null,
      children: [],
      validationGroup: [],
      validationLabel: 'Docs and Comments',
      validationRoute: 'additionalDocs',
      orderType: ['desktopAppraisalReport', 'bpoReport', 'shortTermRentalReport'],
    },
    {
      route: 'certification',
      displayValue: 'Certification',
      jsonPath: 'certification',
      companySettingJsonPath: 'certificationPage.enabled',
      children: [],
      orderType: ['desktopAppraisalReport', 'bpoReport', 'shortTermRentalReport'],
      validationGroup: ['all'],
      validationLabel: 'Certification',
      validationRoute: 'certification',
    },
    {
      route: 'preview',
      displayValue: 'Preview',
      jsonPath: '',
      companySettingJsonPath: null,
      children: [],
      orderType: ['desktopAppraisalReport', 'bpoReport', 'shortTermRentalReport'],
      validationGroup: ['all'],
      validationLabel: 'Preview',
      validationRoute: 'preview',
    },
  ];

  static orderRoutePathList: OrderRoutePathConfig[] = [];

  static comparableSortConfig: { [key in string]: string } = {
    BathroomsDecimal: 'bathroomsTotal',
  };

  static notificationConfig: { [key in string]: { title: string; status: { [key in NotificationStatus]: string } } } = {
    inspection: {
      title: 'Inspection',
      status: {
        INVALID: 'Provided Inspection ID is invalid.',
        SUCCESS: 'Inspection Data successfully fetched.',
        FAILED: 'Failed to fetch inspection details.',
        PENDING: 'Fetching the inspection data is in progress.',
      },
    },
  };

  static validationNotAppliedRoutes: string[] = ['comps-filter'];

  static approachTypeConfig: { [key in ApproachKeys]: ApproachTypes } = {
    salesComparison: ValuationType.sales,
    costApproach: ValuationType.cost,
    shortTermRental: ValuationType.shortTermRental,
    longTermRental: ValuationType.longTermRental,
  };
}
